import { Outlet } from "react-router"
import Footer from "../../components/footer/footer"
import Nav from "../../components/nav/nav"

import tomato_full from '../../images/tomato_full.png'
import doodle from '../../images/doodle_right.png'
import question_doodle from '../../images/question_doodle.png'

const Game = () => {
    return (
        <div className="min-h-screen flex flex-col justify-between bg-black relative overflow-hidden">
            <Nav />
            <div className="flex-1 lg:relative ">
                <div className="h-full w-full lg:absolute left-0 top-0 z-20">
                    <Outlet />
                </div>
                <img src={doodle} alt="doodle" className="absolute -right-4 xl:right-0 3xl:right-0 -bottom-12 z-0 w-28 3xl:w-auto hidden md:block" />
                <img src={tomato_full} alt="tomato top center" className="absolute transform -rotate-45 lg:rotate-0 -right-14 md:right-1/2 lg:right-1/3 top-1/4 md:top-3 3xl:-top-16 z-10 w-24 2xl:w-28 3xl:w-32 block" />
                <img src={tomato_full} alt="tomato left bottom" className="absolute transform -rotate-90 lg:rotate-0 -left-16 md:left-5 bottom-1/2 sm:bottom-1/3 lg:bottom-12 3xl:bottom-20 z-10 w-28 3xl:w-auto block" />

                <img src={question_doodle} alt="doodle" className="absolute -right-48 3xl:right-0 top-64 transform rotate-180 w-64 z-0 block md:hidden" />
                <img src={question_doodle} alt="doodle" className="absolute -left-60 transform -rotate-12 top-64 w-72 z-0 block md:hidden" />
            </div>
            <Footer />
        </div>
    )
}

export default Game