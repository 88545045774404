import tomato from "../../images/tomato.png";

const QuestionCounter = ({ questions, currentIndex }) => {
   return (
      <div className="mt-4 md:mt-12 lg:mt-0">
         <div className={`flex`}>
            {questions.map((e, index) => (
               <img src={tomato} key={e.id + index} alt="tomato" className={`w-6 md:w-10 lg:w-8 2xl:w-10 3xl:w-12 mx-px md:mx-1 ${currentIndex >= index ? "opacity-100" : "opacity-40"}`} />
            ))}
         </div>
      </div>
   );
};

export default QuestionCounter;
