const Polityka = () => {
    return (
        <div className="min-h-screen container mx-auto px-4 lg:px-0 text-white pb-40 pt-10">
            <div className="xl:max-w-4xl mx-auto">
                <div className="text-xl font-bold">
                    POLITYKA PRYWATNOŚCI
                </div>
                <div className="text-xl font-bold">
                    POLITYKA DOTYCZĄCA PLIKÓW COOKIE DLA WWW.MOCPOMIDORA.PL
                </div>
                <br />
                Niniejszy dokument informuje Użytkowników o technologiach, które pomagają stronie
                internetowej osiągnąć cele opisane poniżej. Takie technologie umożliwiają Właścicielowi
                dostęp i przechowywanie informacji (na przykład za pomocą Pliku Cookie) lub korzystanie z
                zasobów (na przykład poprzez uruchomienie skryptu) na urządzeniu Użytkownika podczas
                interakcji z niniejszą Witryną.
                <br /><br />
                Dla uproszczenia wszystkie takie technologie są w niniejszym dokumencie zdefiniowane jako
                „Moduły Śledzące (Trackery)” - chyba że należy je rozróżnić.
                <br /><br />
                Na przykład, mimo, że pliki cookie mogą być używane zarówno w przeglądarkach
                internetowych, jak i mobilnych, mówienie o plikach cookie w kontekście aplikacji mobilnych
                byłoby niewłaściwe, ponieważ są one modułem śledzącym opartym na przeglądarce. Z tego
                powodu w niniejszym dokumencie termin “Pliki cookie” jest używany tylko wtedy, gdy ma
                wskazać ten konkretny typ Modułu Śledzącego.
                <br /><br />
                Niektóre cele, do których wykorzystywane są Moduły Śledzące, mogą również wymagać
                zgody Użytkownika. Za każdym razem, gdy zostanie wyrażona zgoda, można ją w każdej
                chwili swobodnie wycofać, postępując zgodnie z instrukcjami zawartymi w niniejszym
                dokumencie.
                <br /><br />
                Niniejsza Witryna korzysta z Modułów Śledzących zarządzanych bezpośrednio przez
                Właściciela (tak zwanych Modułów Śledzących „strony pierwszej”) oraz Modułów
                Śledzących, które umożliwiają usługi świadczone przez osoby trzecie (tak zwane Moduły
                Śledzące „strony trzeciej”). O ile nie określono inaczej w niniejszym dokumencie, dostawcy
                zewnętrzni mogą uzyskiwać dostęp do zarządzanych przez nich elementów śledzących.
                <br /><br /><br />
                Ważność i okresy wygaśnięcia plików cookie i innych podobnych elementów śledzących
                mogą się różnić w zależności od okresu określonego przez Właściciela lub odpowiedniego
                dostawcę. Niektóre z nich wygasają po zakończeniu sesji przeglądania.
                <br /><br /><br />
                Oprócz tego, co określono w opisach w ramach każdej z poniższych kategorii, Użytkownicy
                mogą znaleźć bardziej precyzyjne i zaktualizowane informacje dotyczące specyfikacji okresu
                użytkowania, a także wszelkie inne istotne informacje – takie, jak obecność innych modułów
                śledzących - w powiązanych politykach prywatności odpowiednich zewnętrznych dostawców
                lub kontaktując się z Właścicielem.
                <br /><br />
                Działania absolutnie niezbędne do funkcjonowania niniejszej Witryny i świadczenia Usługi
                Niniejsza strona internetowa wykorzystuje tak zwane „techniczne” pliki cookie i inne
                podobne narzędzia śledzące do wykonywania działań, które są ściśle niezbędne do działania
                lub świadczenia Usługi.
                <br /><br /><br />
                Trackery innej firmy
                Ten rodzaj usługi analizuje ruch w niniejszej Witrynie, potencjalnie zawierającej Dane
                Osobowe Użytkowników, w celu odfiltrowania ich z części ruchu, wiadomości i treści, które
                są uznawane za SPAM.
                <br /><br />
                Google reCAPTCHA (Google Ireland Limited)
                <br />
                Google reCAPTCHA to usługa ochrony przed spamem świadczona przez Google Ireland
                Limited.
                <br />
                Korzystanie z reCAPTCHA podlega polityce prywatności Google oraz warunkom korzystania. <br />
                <br />
                Przetwarzane dane osobowe: pliki cookie i dane dotyczące sposobu użytkowania.
                <br /><br />
                Miejsce przetwarzania: Irlandia – Polityka Prywatności. Uczestnik Tarczy Prywatności.
                <br /><br />
                Inne aktywności z użyciem Modułów Śledzących <br />
                Wyświetlanie zawartości z platform zewnętrznych <br />
                Ten rodzaj usługi umożliwia przeglądanie treści hostowanych na platformach zewnętrznych
                bezpośrednio ze stron niniejszej Witryny i interakcję z nimi. <br />
                Ten rodzaj usługi może nadal gromadzić dane o ruchu w sieci na stronach, na których usługa
                jest zainstalowana, nawet jeśli Użytkownicy jej nie używają. <br />
                <br />
                Pomiar ruchu <br />
                Niniejsza Witryna korzysta z Modułów Śledzących do pomiaru ruchu i analizowania
                zachowań użytkowników w celu ulepszenia Usługi. <br /> <br />
                Analytics <br />
                Usługi zawarte w tej sekcji umożliwiają Właścicielowi monitorowanie i analizę ruchu
                internetowego i mogą być używane do śledzenia zachowań Użytkowników. <br />
                <br />
                Śledzenie konwersji Google Ads (Google Ireland Limited) <br />
                Śledzenie konwersji Google Ads to usługa analityczna świadczona przez Google Ireland
                Limited, która łączy dane z sieci reklamowej Google Ads z działaniami wykonywanymi w
                niniejszej Witrynie.
                <br /> <br />
                Przetwarzane dane osobowe: pliki cookie i dane dotyczące użytkowania.
                <br /><br />
                Miejsce przetwarzania: Irlandia – Polityka Prywatności. Uczestnik Tarczy Prywatności.
                <br /><br />
                Funkcje raportowania reklam Google Analytics (Google Ireland Limited)
                Google Analytics w niniejszej witrynie ma aktywowane funkcje raportowania reklam, które
                gromadzą dodatkowe informacje z pliku cookie DoubleClick (aktywność w sieci) oraz z
                identyfikatorów wyświetlania reklam urządzenia (aktywność w aplikacji). Umożliwia to
                Właścicielowi analizę określonych zachowań i danych o zainteresowaniach (dane o ruchu i
                dane dotyczące interakcji użytkowników z reklamami) oraz o ile są włączone, dane
                demograficzne (informacje o wieku i płci).
                <br /><br /> <br />
                Użytkownicy mogą zrezygnować z używania plików cookie przez Google, odwiedzając
                ustawienia reklam Google.
                <br /><br />
                Przetwarzane dane osobowe: pliki cookie, unikalne identyfikatory urządzeń do celów
                reklamowych (na przykład Google Advertiser ID lub IDFA) i różne rodzaje Danych określone
                w polityce prywatności usługi.
                <br /><br />
                Miejsce przetwarzania: Irlandia – Privacy Policy – Opt Out. Uczestnik Tarczy Prywatności.
                <br /> <br />
                Raporty danych demograficznych i zainteresowań Google Analytics (Google Ireland Limited)
                Raporty danych demograficznych i zainteresowań Google Analytics to funkcja raportowania
                reklam Google, która udostępnia dane demograficzne i dane o zainteresowaniach w Google
                Analytics dla tej witryny (dane demograficzne oznaczają dane dotyczące wieku i płci).
                <br /><br /><br />
                Użytkownicy mogą zrezygnować z używania plików cookie przez Google, odwiedzając
                ustawienia reklam Google.
                <br /><br />
                Przetwarzane dane osobowe: pliki cookie, unikalne identyfikatory urządzeń do celów
                reklamowych (na przykład Google Advertiser ID lub IDFA).
                <br /><br />
                Miejsce przetwarzania: Irlandia – Privacy Policy – Opt Out. Uczestnik Tarczy Prywatności.
                <br /><br />
                Śledzenie konwersji reklam na Facebooku (Facebook pixel) (Facebook Ireland Ltd) <br />
                Śledzenie konwersji reklam na Facebooku (Facebook pixel) to usługa analityczna świadczona <br />
                przez Facebook Ireland Ltd, która łączy dane z sieci reklamowej Facebooka z działaniami
                wykonywanymi w niniejszej Witrynie. Facebook pixel śledzi konwersje, które można
                przypisać reklamom na Facebooku, Instagramie i Audience Network.
                <br /><br />
                Przetwarzane dane osobowe: pliki cookie i dane dotyczące użytkowania.
                <br /><br />
                Miejsce przetwarzania: Irlandia – Polityka Prywatności. Uczestnik Tarczy Prywatności.
                <br /><br />
                Anonimowe usługi analityczne
                <br />
                Usługi zawarte w tej sekcji umożliwiają Właścicielowi, za pośrednictwem zewnętrznych
                modułów śledzących, na gromadzenie danych analitycznych i zarządzanie nimi w formie
                anonimowej.
                <br /><br />
                Google Analytics z anonimowym adresem IP (Google Ireland Limited) <br />
                Google Analytics to usługa analizy sieci świadczona przez Google Ireland Limited („Google”). <br />
                Google wykorzystuje zebrane dane do śledzenia i badania korzystania z niniejszej Witryny,
                do przygotowywania raportów na temat jej działań i udostępniania ich innym usługom
                Google. <br />
                Google może wykorzystywać zebrane dane do rozpatrywania w szerszym kontekście i
                personalizowania reklam własnej sieci reklamowej.
                <br /><br /><br />
                Ta integracja Google Analytics maskuje Twój adres IP. Działa poprzez skrócenie adresów IP
                użytkowników w państwach członkowskich Unii Europejskiej lub w innych umawiających się
                państwach do Porozumienia o Europejskim Obszarze Gospodarczym. Tylko w wyjątkowych
                przypadkach pełny adres IP zostanie przesłany na serwer Google, a skrócony na obszarze
                USA.
                <br />
                <br />
                Przetwarzane dane osobowe: pliki cookie i dane dotyczące użytkowania.
                <br /><br />
                Miejsce przetwarzania: Irlandia – Privacy Policy – Opt Out. Uczestnik Tarczy Prywatności.
                Targetowanie &amp; Reklamowanie <br />
                Niniejsza witryna korzysta z Modułów Śledzących w celu dostarczania spersonalizowanych
                treści marketingowych na podstawie zachowań użytkowników oraz do obsługi, wyświetlania
                i śledzenia reklam.
                <br /><br />
                Reklama <br />
                Ten rodzaj usługi umożliwia wykorzystywanie Danych użytkownika do celów komunikacji
                reklamowej. Komunikaty te są wyświetlane w postaci banerów i innych reklam w niniejszej
                Witrynie, prawdopodobnie w oparciu o zainteresowania Użytkownika.
                Nie oznacza to, że wszystkie Dane Osobowe są wykorzystywane w tym celu. Informacje i
                warunki użytkowania przedstawiono poniżej. <br />
                Niektóre z wymienionych poniżej usług mogą wykorzystywać Trackery do identyfikacji
                Użytkowników lub mogą wykorzystywać technikę retargetingu behawioralnego, tj.
                Wyświetlanie reklam dostosowanych do zainteresowań i zachowań Użytkownika, w tym
                wykrytych poza niniejszą Witryną. Aby uzyskać więcej informacji, zapoznaj się z polityką
                prywatności odpowiednich usług. <br />
                Oprócz dowolnej funkcji rezygnacji (opt-out) oferowanej przez którąkolwiek z poniższych
                usług, Użytkownicy mogą zrezygnować, odwiedzając stronę rezygnacji (opt-out) Network
                Advertising Initiative. <br /> <br />
                Użytkownicy mogą również zrezygnować z niektórych funkcji reklamowych za pomocą
                odpowiednich ustawień urządzenia, takich jak ustawienia reklam urządzenia dla telefonów
                komórkowych lub ogólne ustawienia reklam. <br /> <br />
                Sieć odbiorców Facebooka (Facebook Audience Network) (Facebook Ireland Ltd)
                Facebook Audience Network to usługa reklamowa świadczona przez Facebook Ireland Ltd
                Aby zrozumieć wykorzystanie danych przez Facebooka, zapoznaj się z Polityką danych
                Facebooka.
                <br /> <br />
                Niniejsza Witryna może wykorzystywać identyfikatory dla urządzeń mobilnych (w tym
                odpowiednio Android Advertising ID lub Advertising Identifier dla iOS) oraz technologie
                podobne do plików cookie w celu uruchomienia usługi Facebook Audience Network. Jednym
                ze sposobów wyświetlania reklam przez Audience Network jest korzystanie z preferencji
                reklam Użytkownika. Użytkownik może to kontrolować w Ustawieniach reklam Facebooka.
                <br /> <br />
                Użytkownicy mogą zrezygnować z targetowania Audience Network za pomocą odpowiednich
                ustawień urządzenia, takich jak ustawienia reklam urządzenia dla telefonów komórkowych
                lub postępując zgodnie z instrukcjami w innych częściach niniejszej polityki prywatności
                dotyczących sieci odbiorców, jeśli są dostępne.
                Przetwarzane dane osobowe: pliki cookie, unikalne identyfikatory urządzeń do celów
                reklamowych (na przykład identyfikator reklamodawcy Google lub IDFA) i dane dotyczące
                użytkowania.
                <br /><br />
                Miejsce przetwarzania: Irlandia – Privacy Policy – Opt Out. Uczestnik Tarczy Prywatności.
                <br /><br />
                Facebook Lookalike Audience (Facebook Ireland Ltd) <br />
                Facebook Lookalike Audience to usługa targetowania reklamowego i behawioralnego <br />
                świadczona przez Facebook Ireland Ltd, która wykorzystuje dane zebrane za pośrednictwem
                Facebook Custom Audience w celu wyświetlania reklam Użytkownikom o podobnym
                zachowaniu do Użytkowników, którzy znajdują się już na liście Custom Audience na
                podstawie ich wcześniejszego wykorzystania niniejszej Witryny lub zaangażowanie w
                odpowiednie treści w aplikacjach i usługach Facebooka. <br />
                Na podstawie tych danych Użytkownikom będą wyświetlane spersonalizowane reklamy,
                zaproponowane przez Facebook Lookalike Audience.
                <br /> <br /> <br />
                Użytkownicy mogą zrezygnować z wykorzystywania przez Facebooka plików cookie do
                personalizacji reklam, odwiedzając tę stronę opt-out. <br /> <br />
                Przetwarzane dane osobowe: pliki cookie i dane dotyczące użytkowania. <br /><br />
                Miejsce przetwarzania: Irlandia – Privacy Policy – Opt Out. Uczestnik Tarczy Prywatności. <br /> <br />
                Remarketing i targetowanie behawioralne <br />
                Ten rodzaj usługi umożliwia niniejszej Witrynie i jej partnerom informowanie,
                optymalizowanie i wyświetlanie reklam w oparciu o wcześniejsze korzystanie z tej Witryny
                przez Użytkownika. <br />
                Ta aktywność jest ułatwiona dzięki śledzeniu danych o użytkowaniu i wykorzystaniu
                elementów śledzących do zbierania informacji, które są następnie przesyłane do partnerów
                zarządzających działaniami remarketingowymi i behawioralnymi.
                Niektóre usługi oferują opcję remarketingu w oparciu o listy adresów e-mail.
                Oprócz dowolnej funkcji rezygnacji oferowanej przez dowolną z poniższych usług,
                Użytkownicy mogą zrezygnować, odwiedzając stronę Network Advertising Initiative opt-out.
                <br /><br />
                Użytkownicy mogą również zrezygnować z niektórych funkcji reklamowych za pomocą
                odpowiednich ustawień urządzenia, takich jak ustawienia reklam urządzenia dla telefonów
                komórkowych lub ogólne ustawienia reklam <br />
                Remarketing z Google Analytics (Google Ireland Limited) <br />
                Remarketing z Google Analytics to usługa remarketingu i targetowania behawioralnego
                świadczona przez Google Ireland Limited, która łączy śledzenie wykonywane przez Google
                Analytics i jego pliki cookie z siecią reklamową Google Ads i Doubleclick Cookie.
                Przetwarzane dane osobowe: pliki cookie i dane dotyczące użytkowania.
                <br /><br />
                Miejsce przetwarzania: Irlandia – Privacy Policy – Opt Out. Uczestnik Tarczy Prywatności. <br /> <br />
                Facebook Custom Audience (Facebook Ireland Ltd) <br />
                Facebook Custom Audience to usługa remarketingu i targetowania behawioralnego
                świadczona przez Facebook Ireland Ltd, która łączy aktywność niniejszej Witryny z siecią
                reklamową Facebooka.
                <br /><br />
                Użytkownicy mogą zrezygnować z wykorzystywania przez Facebooka plików cookie do
                personalizacji reklam, odwiedzając tę stronę opt-out.
                <br /><br />
                Przetwarzane dane osobowe: pliki cookie i adres email.
                <br /><br />
                Miejsce przetwarzania: Irlandia – Polityka Prywatności – Opt Out. Uczestnik Tarczy
                Prywatności.
                <br /><br />
                Google Ads Remarketing (Google Ireland Limited) <br />
                Google Ads Remarketing to usługa remarketingu i targetowania behawioralnego świadczona
                przez Google Ireland Limited, która łączy aktywność niniejszej Witryny z siecią reklamową <br />
                Google Ads i DoubleClick Cookie.
                <br /><br />
                Użytkownicy mogą zrezygnować z wykorzystywania przez Google plików cookie do
                personalizacji reklam, odwiedzając Ustawienia reklam Google. <br /> <br />
                Przetwarzane dane osobowe: pliki cookie i dane dotyczące użytkowania. <br /> <br />
                Miejsce przetwarzania: Irlandia – Polityka Prywatności – Opt Out. Uczestnik Tarczy
                Prywatności. <br /><br />
                Facebook Remarketing (Facebook Ireland Ltd)
                <br /><br />
                Remarketing na Facebooku to usługa remarketingu i targetowania behawioralnego
                świadczona przez Facebook Ireland Ltd, która łączy aktywność niniejszej Witryny z siecią
                reklamową Facebooka.
                <br /><br />
                Przetwarzane dane osobowe: pliki cookie i dane dotyczące użytkowania.
                <br /><br />
                Miejsce przetwarzania: Irlandia – Polityka Prywatności – Opt Out. Uczestnik Tarczy
                Prywatności.
                <br /><br />
                Jak zarządzać preferencjami i wyrażać lub cofać zgodę <br />
                Istnieją różne sposoby zarządzania preferencjami związanymi z Trackerem oraz wyrażania i
                wycofywania zgody, w stosownych przypadkach:
                <br /> <br />
                Użytkownicy mogą zarządzać preferencjami związanymi z Trackerami bezpośrednio z
                poziomu ustawień własnego urządzenia, na przykład uniemożliwiając używanie lub
                przechowywanie elementów śledzących.
                <br /><br />
                Dodatkowo, ilekroć korzystanie z Trackerów wymaga zgody, Użytkownicy mogą wyrazić lub
                wycofać taką zgodę, ustawiając swoje preferencje w powiadomieniu o plikach cookie lub
                aktualizując takie preferencje za pomocą odpowiedniego widżetu preferencji zgody, jeśli jest
                dostępny.
                <br /><br />
                Możliwe jest również, za pośrednictwem odpowiednich funkcji przeglądarki lub urządzenia,
                usunięcie wcześniej zapisanych elementów śledzących, w tym tych używanych do
                zapamiętania wstępnej zgody użytkownika.
                Inne elementy śledzące w pamięci lokalnej przeglądarki można wyczyścić, usuwając historię
                przeglądania.
                <br /><br />
                W odniesieniu do wszelkich zewnętrznych modułów śledzących, Użytkownicy mogą
                zarządzać swoimi preferencjami i wycofać swoją zgodę za pośrednictwem powiązanego linku
                do rezygnacji (jeśli jest dostępny), korzystając ze środków wskazanych w polityce
                prywatności strony trzeciej lub kontaktując się ze stroną trzecią.
                <br /><br />
                Lokalizowanie ustawień modułów śledzących
                <br />
                Użytkownicy mogą na przykład znaleźć informacje o tym, jak zarządzać plikami Cookies w
                najczęściej używanych przeglądarkach pod następującymi adresami:
                <br /><br />
                Google Chrome
                <br /><br />
                Mozilla Firefox
                <br /><br />
                Apple Safari
                <br /><br />
                Microsoft Internet Explorer
                <br /><br />
                Microsoft Edge
                <br /><br />
                Brave
                <br /><br />
                Opera
                <br /><br />
                Użytkownicy mogą również zarządzać niektórymi kategoriami modułów śledzących
                używanymi w aplikacjach mobilnych, rezygnując z odpowiednich ustawień urządzenia, takich
                jak ustawienia reklam urządzenia dla urządzeń mobilnych lub ogólne ustawienia śledzenia
                (użytkownicy mogą otworzyć ustawienia urządzenia, wyświetlić i wyszukać odpowiednie
                ustawienia). <br /><br />
                Rezygnacje z określonych branż reklamowych <br />
                Niezależnie od powyższego Użytkownicy mogą postępować zgodnie z instrukcjami
                dostarczonymi przez YourOnlineChoices (UE) Network Advertising Initiative (USA) oraz
                Digital Advertising Alliance (USA), DAAC (Kanada), DDAI (Japonia) lub innych podobnych
                usługodawców. Takie inicjatywy pozwalają Użytkownikom na wybór preferencji śledzenia dla
                większości narzędzi reklamowych. W związku z tym Właściciel zaleca, aby Użytkownicy
                korzystali z tych zasobów dodatkowo, oprócz informacji zawartych w tym dokumencie. <br /><br />
                Digital Advertising Alliance oferuje aplikację o nazwie AppChoices która pomaga
                Użytkownikom kontrolować reklamy oparte na zainteresowaniach w aplikacjach mobilnych.
                <br /><br />
                Właściciel i administrator danych
                <br />
                Dawtona Sp. z o.o.
                <br /><br />
                ul. Bieniewicka 52
                <br /><br />
                05-870 Błonie
                <br /><br />
                e-mail: iod@dawtona.pl
                <br /><br />
                Kontakt do właściciela: dawtona@dawtona.pl
                <br /><br />
                Ponieważ korzystanie z zewnętrznych elementów śledzących za pośrednictwem niniejszej
                witryny nie może być w pełni kontrolowane przez Właściciela, wszelkie konkretne
                odniesienia do zewnętrznych elementów śledzących należy traktować jako orientacyjne. W
                celu uzyskania pełnych informacji Użytkownicy proszeni są o zapoznanie się z polityką
                prywatności odpowiednich usług stron trzecich wymienionych w niniejszym dokumencie.
                <br /><br />
                Biorąc pod uwagę obiektywną złożoność technologii śledzenia, zachęca się Użytkowników do
                skontaktowania się z Właścicielem, w razie chęci otrzymania dalszych informacji na temat
                korzystania z takich technologii przez niniejszą Witrynę.
                Definicje i odniesienia prawne
                Dane osobowe (lub Dane)
                <br /><br />

                Wszelkie informacje, które bezpośrednio, pośrednio lub w związku z innymi informacjami - w
                tym osobisty numer identyfikacyjny - pozwalają na identyfikację lub rozróżnienie osoby
                fizycznej.
                <br /><br />
                Dane dotyczące użytkowania
                <br />
                Informacje zbierane automatycznie za pośrednictwem niniejszej Witryny (lub usług stron
                trzecich wykorzystywanych w niniejszej Witrynie), które mogą obejmować: adresy IP lub
                nazwy domen komputerów, z których korzystają Użytkownicy korzystający z niniejszej
                Witryny, adresy URI (Uniform Resource Identifier), czas żądania, sposób przesłania żądania
                do serwera, rozmiar pliku otrzymanego w odpowiedzi, kod numeryczny określający status
                odpowiedzi serwera (wynik pozytywny, błąd itp.), kraj pochodzenia, funkcje przeglądarki i
                systemu operacyjnego, z którego korzysta Użytkownik, różne szczegóły dotyczące czasu na
                wizytę (np. czas spędzony na każdej stronie w Aplikacji) oraz szczegóły dotyczące ścieżki
                przebytej w Aplikacji, ze szczególnym uwzględnieniem kolejności odwiedzanych stron i inne
                parametrów dotyczących systemu operacyjnego urządzenia i / lub środowiska
                informatycznego Użytkownika.
                <br /><br />
                Użytkownik
                <br />
                Osoba korzystająca z niniejszej Witryny, która, o ile nie określono inaczej, pokrywa się z
                Podmiotem Danych.
                <br /><br />
                Podmiot Danych
                <br />
                Osoba fizyczna, której dotyczą Dane Osobowe.
                <br /><br />
                Przetwarzający dane (lub Inspektor danych)
                <br />
                Osoba fizyczna lub prawna, organ publiczny, agencja lub inny organ, który przetwarza Dane
                Osobowe w imieniu Administratora, zgodnie z opisem w niniejszej polityce prywatności.
                <br /><br />
                Administrator danych (lub Właściciel)
                <br />
                Osoba fizyczna lub prawna, organ publiczny, agencja lub inny organ, który samodzielnie lub
                wspólnie z innymi określa cele i sposoby przetwarzania Danych Osobowych, w tym środki
                bezpieczeństwa dotyczące działania i korzystania z niniejszej Witryny. Administratorem
                danych, o ile nie określono inaczej, jest Właściciel niniejszej Witryny.
                <br /><br />
                Niniejsza Witryna (lub niniejsza Aplikacja lub strona internetowa)
                <br />
                Strona internetowa określona wskazanym adresem, która zawiera opisane w dokumencie
                sposoby gromadzenia i przetwarzania danych osobowych użytkownika.
                <br /><br />
                Usługa
                <br />
                Usługa świadczona przez niniejszą Witrynę zgodnie z opisem w warunkach względnych (jeśli
                są dostępne) oraz w niniejszej witrynie / aplikacji.
                <br /><br />
                Unia Europejska (lub UE) <br />
                O ile nie określono inaczej, wszystkie zawarte w niniejszym dokumencie odniesienia do Unii
                Europejskiej obejmują wszystkie obecne państwa członkowskie Unii Europejskiej i
                Europejskiego Obszaru Gospodarczego.
                <br /><br />

                Cookie
                <br />
                Pliki cookie to moduły śledzące składające się z małych zestawów danych przechowywanych
                w przeglądarce użytkownika.
                <br /><br />
                Tracker
                <br />
                Tracker oznacza dowolną technologię - np. Pliki cookie, unikalne identyfikatory, sygnały
                nawigacyjne w sieci Web, wbudowane skrypty, e-tagi i odciski palców - która umożliwia
                śledzenie Użytkowników, na przykład poprzez uzyskiwanie dostępu lub przechowywanie
                informacji na urządzeniu Użytkownika.
                <br /><br />
                Informacja prawna
                <br />
                Niniejsze oświadczenie o ochronie prywatności zostało przygotowane w oparciu o przepisy
                wielu aktów prawnych, w tym art. 13/14 rozporządzenia (UE) 2016/679 (ogólne
                rozporządzenie o ochronie danych).
                <br /><br />
                Niniejsza polityka prywatności dotyczy wyłącznie Witryny www.MocPomidora.pl
            </div>
        </div>
    )
}

export default Polityka