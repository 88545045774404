import tomato_slice from '../../images/tomato_slice.png'
import tomato_full from '../../images/tomato_full.png'
import doodle from '../../images/question_doodle.png'
import { deOrphan } from '../../utils/deorphan'


const prefix = process.env.NODE_ENV === 'production' ? 'https://api.mocpomidora.pl' : 'https://api.mocpomidora.pl'

const Reason = ({ reason, answered, img }) => {
    return (
        <div className="w-full md:px-32 lg:px-24 2xl:px-32 mt-10 lg:mt-0 relative z-30">
            <div className='relative'>
                <div className="reason-container z-10">
                    <div className="absolute left-0 top-0 w-full h-full bg-white border-8 border-accent">
                        <div className="relative w-full h-full">
                            {img ?
                                <img src={`../images/${img.name}`} className='w-full h-full object-cover' alt="" />
                                :
                                null
                            }
                            {answered && <div className="absolute left-0 top-0 w-full h-full bg-green-600 bg-opacity-90 p-4 2xl:p-10 flex items-center flex-col justify-center text-center 2xl:text-lg">
                                {/* <p className='uppercase font-bold mb-4'>Poprawna odpowiedź</p> */}
                                <div className='' dangerouslySetInnerHTML={{ __html: deOrphan(reason) }}>

                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                <img src={tomato_slice} alt="tomato slice" className='absolute -left-5 md:-left-12 -bottom-2 md:-bottom-6 z-20 transform rotate-45 w-16 md:w-24 2xl:w-28' />
                <img src={tomato_full} alt="tomato full" className='absolute -right-9 md:-right-16 bottom-10 z-0 w-14 md:w-24 2xl:w-32' />
                <img src={doodle} alt="tomato slice" className='absolute -right-20 2xl:-right-36 -top-12 md:-top-24 z-0 w-44 md:w-60 2xl:w-auto' />
            </div>
        </div>
    )
}

export default Reason