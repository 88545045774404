import tomato from '../../images/tomato.png'

const Loading = () => {
    return (
        <div className="w-full h-full py-10 flex justify-center items-center">
            <img src={tomato} alt="tomato" className='w-24 h-24 tomato' />
        </div>
    )
}

export default Loading