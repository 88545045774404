const Regulamin = () => {
    return (
        <div className="min-h-screen container mx-auto px-4 lg:px-0 text-white pb-40">
            <p className="text-xl font-bold mb-4 mt-4 text-center">REGULAMIN KONKURSU – „Moc Pomidora”</p>
            <div className="mt-5">
                <p className="text-xl font-bold mt-4 text-center">§ 1</p>
                <p className="text-xl font-bold mb-4 text-center">POSTANOWIENIA OGÓLNE</p>
            </div>
            <ul className="xl:max-w-4xl mx-auto list-decimal">
                <li className="my-3">
                    Niniejszy Regulamin określa zasady udziału w Konkursie oraz zasady jego przebiegu, nadzoru
                    nad jego prawidłowym przeprowadzeniem oraz procesu reklamacyjnego (dalej:
                    „Regulamin”).
                </li>
                <li className="my-3">
                    Konkurs prowadzony jest pod nazwą “Moc Pomidora” (dalej: „Konkurs”).
                </li>
                <li className="my-3">
                    Organizatorem oraz fundatorem nagród w Konkursie jest Dawtona sp. z o.o.,
                    z siedzibą w Błoniu, ul. Bieniewicka 52, 05-870 Błonie, wpisana do Rejestru Przedsiębiorców
                    Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m. st. Warszawy w
                    Warszawie, XIV Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS
                    0000652355, NIP 1182135917, REGON 366238956, o kapitale zakładowym w wysokości
                    304 960 000,00 zł (zwana dalej „Organizatorem”).

                </li>
                <li className="my-3">
                    Konkurs przeprowadzany jest na stronie MocPomidora.pl (dalej: „Strona konkursu”).
                </li>
                <li className="my-3">
                    Konkurs odbywa się na terenie Rzeczypospolitej Polskiej.
                </li>
                <li className="my-3">
                    Czas trwania konkursu obejmuje okres od godziny 00:01 dnia 13.01.2022 r. do godziny 23:59
                    dnia 30.01.2022 r., przy czym czynności związane z przyznawaniem i odbiorem nagród oraz
                    rozpatrywaniem reklamacji zakończą się do dnia 02.03.2022 r.
                </li>
                <li className="my-3">
                    Organizator Konkursu zobowiązuje się ufundować nagrody określone szczegółowo w
                    Regulaminie.
                </li>
                <li className="my-3">
                    Konkurs nie jest grą losową, loterią fantową, zakładem wzajemnym, loterią promocyjną, grą,
                    której wynik zależy od przypadku, ani żadną inną w rozumieniu ustawy z dnia 19 listopada
                    2009 r. o grach hazardowych (t.j. Dz.U.2018.165 z późn. zm.).
                </li>
                <li className="my-3">
                    Organizator jest składającym przyrzeczenie publiczne w rozumieniu art. 919 kodeksu
                    cywilnego (t.j. Dz.U.2017.459 z późn. zm.).
                </li>
                <li className="my-3">
                    Udział w Konkursie jest dobrowolny. Uczestnik przed przystąpieniem do Konkursu powinien
                    zapoznać się z treścią Regulaminu. Uczestnik, przystępując do konkursu, zobowiązuje się do
                    przestrzegania zawartych w Regulaminie zasad, jak również potwierdza, iż spełnia wszystkie
                    warunki, które uprawniają go do udziału w Konkursie. Przesłanie zgłoszenia do konkursu
                    oznacza jednoczesną akceptację przez uczestnika postanowień Regulaminu.
                </li>
            </ul>

            <div className="mt-5">
                <p className="text-xl font-bold mt-4 text-center">§ 2</p>
                <p className="text-xl font-bold mb-4 text-center">ZASADY UCZESTNICTWA W KONKURSIE</p>
            </div>
            <ul className="xl:max-w-4xl mx-auto list-decimal">
                <li className="my-3">
                    Uczestnikiem niniejszego Konkursu może być osoba fizyczna, będąca konsumentem w
                    rozumieniu art. 22 1 kodeksu cywilnego (t.j. Dz.U.2017.459 z późn. zm.), która podczas trwania
                    konkursu spełnia następujące warunki:
                    <ul className="pl-5 list-none">
                        <li>
                            a. jest osobą pełnoletnią;
                        </li>
                        <li>
                            b. posiada miejsce zamieszkania na terytorium Rzeczpospolitej Polskiej;
                        </li>
                        <li>
                            c. zaakceptowała postanowienia niniejszego Regulaminu;
                        </li>
                        <li>
                            d. posiada pełną zdolność do czynności prawnych;
                        </li>
                    </ul>
                    (dalej: „Uczestnik”).
                </li>
                <li className="my-3">
                    Zgłoszenie udziału w Konkursie musi być dokonane w sposób wskazany w Regulaminie.
                    Niespełnienie któregokolwiek z warunków zgłoszenia udziału spowoduje jego nieważność.
                </li>
                <li className="my-3">
                    W Konkursie można brać udział wyłącznie osobiście. Niedopuszczalne dokonywanie zgłoszeń
                    konkursowych w imieniu osób trzecich.
                </li>
                <li className="my-3">
                    Uczestnikami Konkursu nie mogą być pracownicy Organizatora, podmiotów działających na
                    zlecenie Organizatora w związku z przeprowadzeniem Konkursu, oraz członkowie rodzin tych
                    osób. Za członków rodziny w rozumieniu niniejszego Regulaminu uznaje się̨: wstępnych,
                    zstępnych, rodzeństwo, małżonków, a także osoby pozostające z ww. osobami w stosunku
                    przysposobienia.
                </li>
                <li className="my-3">
                    W przypadkach wykrycia działań niezgodnych z Regulaminem, próby wpływania na
                    wyłonienie Zwycięzcy w sposób niedozwolony lub korzystania z dodatkowego
                    oprogramowania w celu manipulowania udziałem w Konkursie lub wynikiem Konkursu,
                    Uczestnik zostanie wykluczony z Konkursu.
                </li>
            </ul>

            <div className="mt-5">
                <p className="text-xl font-bold mt-4 text-center">§ 3</p>
                <p className="text-xl font-bold mb-4 text-center">KOMISJA KONKURSOWA</p>
            </div>
            <ul className="xl:max-w-4xl mx-auto list-decimal">
                <li className="my-3">
                    Celem zapewnienia prawidłowej organizacji i przebiegu Konkursu, a w szczególności
                    w celu dokonania oceny prawidłowości zgłoszeń do Konkursu oraz wyłonienia osób biorących
                    w nim udział i wyboru Zwycięzców Konkursu, Organizator powoła komisję konkursową (dalej:
                    „Komisja”).
                </li>
                <li className="my-3">
                    W skład Komisji wejdą 3 (słownie: trzy) osoby wskazane przez Organizatora.
                </li>
                <li className="my-3">
                    Komisja wybierze 23 (słownie: dwadzieścia trzy) zwycięskie zgłoszenia konkursowe, oceniając
                    nadesłane zgłoszenia wyłącznie na podstawie kryterium kreatywności i sporządzi z czynności
                    odpowiedni protokół.
                </li>
            </ul>

            <div className="mt-5">
                <p className="text-xl font-bold mt-4 text-center">§ 4</p>
                <p className="text-xl font-bold mb-4 text-center">ZASADY PRZEPROWADZENIA KONKURSU</p>
            </div>
            <ul className="xl:max-w-4xl mx-auto list-decimal">
                <li className="my-3">
                    W celu wzięcia udziału w Konkursie, Uczestnik powinien w czasie jego trwania spełnić łącznie
                    warunki określone w Regulaminie, w szczególności:
                    <ul className="pl-5 list-none">
                        <li>
                            a. posiadać kompatybilne z aplikacją internetową urządzenie posiadające prawidłowo
                            zainstalowaną najnowszą wersję systemu operacyjnego Windows, Mac OS, iOS lub
                            Android oraz najnowszą wersję przeglądarki Firefox, Chrome, Safari lub Edge bez
                            żadnych dodatków w formie tzw. wtyczek, czy oprogramowania mogących mieć
                            wpływ na ich funkcjonowanie;
                        </li>
                        <li>
                            b. Posiadać dostęp do łącza internetowego o szybkości transferu danych nie mniejszej
                            niż 1Mb/s zarówno dla ruchu przychodzącego (odbieranie) jak
                            i wychodzącego (wysyłanie);
                        </li>
                        <li>
                            c. wziąć udział w Quizie poprzez udzielenie odpowiedzi na 10 kolejno pojawiających się
                            pytań – zadanie konkursowe zostało szczegółowo opisane w ust. 3 poniżej;
                        </li>
                        <li>
                            d. zaakceptować Regulamin zaznaczając pole akceptacji w aplikacji konkursowej
                            ustanowionej w oknie strony www.MocPomidora.pl;
                        </li>
                        <li>
                            e. podać imię (pole: Twoje Imię) oraz adres e-mail (pole: Twój Email)
                        </li>
                        <li>
                            f. wyrazić zgodę na przetwarzanie danych osobowych tj. nazwa i adres email na
                            potrzeby realizacji jego udziału w Konkursie zgodnie z § 7 Regulaminu zaznaczając
                            pole akceptacji w aplikacji konkursowej ustanowionej w oknie strony
                            www.mocpomidora.pl;
                        </li>
                        <li>
                            g. potwierdzić fakt zapoznania się z klauzulą informacyjną zawartą w Regulaminie;
                        </li>
                    </ul>
                </li>
                <li className="my-3">
                    Organizator wykluczy Uczestnika oraz usunie z Konkursu jego wynik w następujących
                    przypadkach:
                    <ul className="pl-5 list-none">
                        <li>
                            a. naruszenia jakichkolwiek praw osób trzecich, w tym w szczególności: dóbr osobistych
                            i praw autorskich;
                        </li>
                        <li>
                            b. gdy wyniki zostały uzyskane dzięki oszustwom wobec Organizatora lub innych
                            Uczestników Konkursu;
                        </li>
                        <li>
                            c. gdy odpowiedzi zawierają wulgaryzmy, treści obraźliwe, polityczne, propagandowe,
                            religijne lub treści sprzeczne z prawem oraz naruszające jakiekolwiek prawa osób
                            trzecich, w tym w szczególności dobra osobiste i prawa autorskie oraz zawierające w
                            Odpowiedzi Konkursowej treści reklamowe dotyczące podmiotów innych niż
                            Organizator oraz gdy uczestnik nie posiada praw autorskich lub praw zależnych do
                            Zadania Konkursowego i jego wszystkich elementów w zakresie wskazanym w
                            Regulaminie.
                        </li>
                    </ul>
                </li>
                <li className="my-3">
                    Konkurs polega na:
                    <ul className="pl-5 list-none">
                        <li>
                            a. wzięciu udziału w Quizie, w którym zadaniem Uczestnika jest udzielenie odpowiedzi
                            na kolejne pytania zadawane w formie pytań zamkniętych z jedną prawidłową
                            odpowiedzią. Za każdą poprawną odpowiedź Uczestnik otrzymuje punkt w postaci
                            pomidorka, który pozwoli na odsłonięcie jednej litery w pytaniu, o którym mowa w
                            pkt. b poniżej. Udzielenie poprawnych odpowiedzi w Quizie nie jest warunkiem
                            możliwości uczestniczenia w dalszej części Konkursu;
                        </li>
                        <li>
                            b. udzieleniu kreatywnej odpowiedzi nie dłuższej niż 750 znaków na pytanie
                            konkursowe, którego pełnego brzmienia nie widzi żaden z Uczestników („Praca
                            Konkursowa”),
                        </li>
                        <li>
                            c. wysłaniu zgłoszenia poprzez naciśnięcie przycisku „Wyślij”.
                        </li>
                    </ul>
                </li>
                <li className="my-3">
                    Do Konkursu mogą zostać zgłoszone jedynie Prace związane z tematyką konkursu i markami
                    Dawtona oraz Wielka Orkiestra Świątecznej Pomocy.
                </li>
                <li className="my-3">
                    Dopuszczone do Konkursu Prace Konkursowe mogą zostać opublikowane w szczególności na
                    profilu Dawtona na Facebooku, na profilu Dawtona Instagramie, platformach marki Dawtona
                    jak strona internetowa na zasadach określonych w §8 Regulaminu.
                </li>
                <li className="my-3">
                    Uczestnik może przysłać więcej niż jedno zgłoszenie, jednakże zgodnie z §5 ust. 5 jeden
                    uczestnik może wygrać tylko jedną nagrodę.
                </li>
                <li className="my-3">
                    Uczestnicy mogą zadawać ewentualne pytania dotyczące Konkursu oraz jego przebiegu
                    wysyłając wiadomości za pomocą wiadomości e-mail na adres podany w zakładce Regulamin
                    tj. pomoc@mocpomidora.pl.
                </li>
            </ul>

            <div className="mt-5">
                <p className="text-xl font-bold mt-4 text-center">§ 5</p>
                <p className="text-xl font-bold mb-4 text-center">NAGRODY</p>
            </div>
            <ul className="xl:max-w-4xl mx-auto list-decimal">
                <li className="my-3">
                    Nagrodami w Konkursie są:
                    <ul className="pl-5 list-none">
                        <li>
                            a. Jedna (1) nagroda 1-go stopnia – zestaw VR Oculus Quest.
                        </li>
                        <li>
                            b. Dwie (2) nagrody 2-go stopnia – mobilny projektor multimedialny Nebula Mars II Pro.
                        </li>
                        <li>
                            c. Dwadzieścia (20) nagród 3-go stopnia – zestaw upominkowy Dawtona zawierający
                            produkty marki Dawtona (sok Dawtona o pojemności 1L – sok pomidorowy,
                            pomidorowy pikantny, sok z buraka, sok pomidorowy z ogórkiem, ketchup 450g
                            Dawtona – łagodny, ziołowy, bez dodatku cukru, pikantny, bardzo pikantny) i Drugie
                            Śniadanie (20 sztuk musów XXL 180g mix smaków).
                        </li>
                    </ul>
                </li>
                <li className="my-3">
                    Wartość wszystkich nagród wynosi 9222,15 zł (dziewięć tysięcy dwieście dwadzieścia dwa
                    złote 15/100) brutto, w tym:
                    <ul className="pl-5 list-none">
                        <li>
                            a. wartość nagrody 1-go stopnia 2500 zł (dwa tysiące pięćset złotych 00/100) brutto;
                        </li>
                        <li>
                            b. wartość nagrody 2-go stopnia 2000 zł (dwa tysiące złotych 00/100) brutto;
                        </li>
                        <li>
                            c. wartość nagrody 3-go stopnia 100 zł (sto złotych 00/100) brutto;
                        </li>
                        <li>
                            d. Nagrody dodatkowe: do nagród pierwszego oraz drugiego stopnia Organizator
                            przyzna Zwycięzcom dodatkową nagrodę pieniężną w wysokości 11,11% zł wartości
                            każdej nagrody, co daje łączną kwotę 722,15 (siedemset dwadzieścia dwa złote
                            15/100) które zostaną wpłacone w całości przez Organizatora na poczet podatku
                            dochodowego od nagród.
                        </li>
                    </ul>
                </li>
                <li className="my-3">
                    Z uwagi na wartość jednostkowej nagrody trzeciego stopnia tj. 100 zł (sto złotych 00/100)
                    brutto, Organizator zwolniony jest z odprowadzania podatku od nagród, zgodnie z
                    powszechnie obowiązującymi przepisami prawa podatkowego.
                </li>
                <li className="my-3">
                    Zwycięzca wygrywa jedną nagrodę. Jeden Uczestnik może wygrać w konkursie jeden raz
                    podczas całego okresu jego trwania.
                </li>
                <li className="my-3">
                    Zwycięzcom Konkursu nie przysługuje prawo wymiany Nagród na gotówkę̨ ani na nagrodę̨
                    innego rodzaju.
                </li>
                <li className="my-3">
                    Zwycięzcy nie przysługuje prawo zastrzegania szczególnych cech nagród, takich, jak np. kolor,
                    wielkość, kształt czy rodzaj nagrody.
                </li>
                <li className="my-3">
                    Zwycięzca Konkursu może zrzec się Nagrody, przy czym rezygnacja z części Nagrody oznacza
                    zrzeczenie się całości Nagrody.
                </li>
            </ul>

            <div className="mt-5">
                <p className="text-xl font-bold mt-4 text-center">§ 6</p>
                <p className="text-xl font-bold mb-4 text-center">POWIADOMIENIE O WYGRANEJ</p>
            </div>
            <ul className="xl:max-w-4xl mx-auto list-decimal">
                <li className="my-3">
                    Powiadomienie Zwycięzców nastąpi najpóźniej 15.02.2022 roku w formie wiadomości
                    prywatnej wysłanej na adres e-mail podany w zgłoszeniu.
                </li>
                <li className="my-3">
                    Zwycięzca w następstwie otrzymania Powiadomienia, zobowiązany jest do wysłania w
                    terminie 5 dni, w wiadomości e-mail następujących informacji koniecznych do wydania i
                    przekazania nagrody, dane osobowe Uczestnika, czyli imię i nazwisko, adres
                    korespondencyjny, adres zamieszkania, numer telefonu.
                </li>
                <li className="my-3">
                    Nagroda zostanie przesłana na wskazany adres na terenie Polski w formie przesyłki
                    kurierskiej. Nagroda zostanie wydana po uprzednim zweryfikowaniu przez kuriera tożsamości
                    odbiorcy.
                </li>
                <li className="my-3">
                    Nagrody zostaną wydane zgodnie z obowiązującymi przepisami prawa podatkowego.
                </li>
                <li className="my-3">
                    Organizator nie ponosi odpowiedzialności z powodu niemożności powiadomienia Zwycięzcy
                    o wygranej w wypadku podania przez Uczestnika błędnych danych kontaktowych, danych
                    nieprawdziwych lub niekompletnych lub z powodu zaistnienia zdarzeń siły wyższej, w tym
                    zdarzenia nieprzewidzianego, niezależnego i niezawinionego przez Organizatora oraz
                    mającego charakter trwały.
                </li>
            </ul>

            <div className="mt-5">
                <p className="text-xl font-bold mt-4 text-center">§ 7</p>
                <p className="text-xl font-bold mb-4 text-center">OCHRONA DANYCH OSOBOWYCH</p>
            </div>
            <ul className="xl:max-w-4xl mx-auto list-decimal">
                <li className="my-3">
                    Administratorem Państwa danych osobowych jest Dawtona sp. z o.o.,
                    z siedzibą w Błoniu, ul. Bieniewicka 52, 05-870 Błonie, wpisana do Rejestru Przedsiębiorców
                    Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m. st. Warszawy w
                    Warszawie, XIV Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS
                    0000652355, NIP 1182135917, REGON 366238956, o kapitale zakładowym w wysokości 304 960
                    000,00 zł (zwana dalej „Organizatorem”) (dalej „Dawtona”).
                </li>
                <li className="my-3">
                    We wszelkich sprawach związanych z przetwarzaniem Państwa danych osobowych przez
                    Dawtona prosimy o kontakt pod adresem email: iod@dawtona.pl na adres wskazany w ust. 1 z
                    dopiskiem „dane osobowe”.
                </li>
                <li className="my-3">
                    Nagroda zostanie przesłana na wskazany adres na terenie Polski w formie przesyłki
                    kurierskiej. Nagroda zostanie wydana po uprzednim zweryfikowaniu przez kuriera tożsamości
                    odbiorcy.
                </li>
                <li className="my-3">
                    W związku z uczestnictwem w Konkursie będą przetwarzane następujące Państwa dane:
                    <ul className="pl-5 list-none">
                        <li>
                            a. Imię Uczestnika podane w formularzu;
                        </li>
                        <li>
                            b. Adres e-mail Uczestnika podany w formularzu;
                        </li>
                        <li>
                            c. w przypadku zwycięzców Konkursu oraz obsługi reklamacyjnej: (i) imię, (ii) nazwisko,
                            (iii) adres zamieszkania/do korespondencji, (iv) adres email oraz (v) numer telefonu;
                        </li>
                        <li>
                            d. w przypadku zwycięzców Konkursu – adres zamieszkania w celu ustalenia właściwego
                            Urzędu Skarbowego – na potrzeby odprowadzenia podatku dochodowego.
                        </li>
                    </ul>
                </li>
                <li className="my-3">
                    Dane osobowe, będą przetwarzane w celach:
                    <ul className="pl-5 list-none">
                        <li>
                            a. udziału w Konkursie (na podstawie wyrażonej zgody);
                        </li>
                        <li>
                            b. wyłonienia oraz ogłoszenia zwycięzcy Konkursu (na podstawie wyrażonej zgody);
                        </li>
                        <li>
                            c. utrwalania i rozpowszechniania Zadania Konkursowego zgodnie z § 7 niniejszego
                            Regulaminu (na podstawie udzielonej zgody);
                        </li>
                        <li>
                            d. obsługi spraw podatkowych, wynikających z obowiązujących przepisów prawa;
                        </li>
                        <li>
                            e. obsługi wysyłki Nagród oraz zgłaszanych reklamacji.
                        </li>
                    </ul>
                </li>
                <li className="my-3">
                    Z uwagi na zapewnienie odpowiedniej organizacji w bieżących sprawach dotyczących organizacji i
                    przeprowadzenia Konkursu odbiorcą Państwa danych osobowych mogą być:
                    <ul className="pl-5 list-none">
                        <li>
                            a. agencje marketingowe będące wykonawcą Konkursu działające na zlecenie Dawtona
                            na podstawie stosownych umów powierzenia lub przepisów prawa;
                        </li>
                        <li>
                            b. dostawcy usług prawnych i doradczych wspierających Dawtona w dochodzeniu
                            należytych roszczeń (w szczególności kancelarie prawne, firmy windykacyjne);
                        </li>
                        <li>
                            c. dostawcy usług zaopatrujących Dawtona w rozwiązania techniczne oraz
                            organizacyjne, wspierających procesy biznesowe (w szczególności dostawcy usług
                            informatycznych, firmy kurierskie i pocztowe) działające na zlecenie Dawtona na
                            podstawie stosownych umów lub przepisów prawa.
                        </li>
                    </ul>
                </li>
                <li className="my-3">
                    Państwa dane nie będą przekazywane poza teren Europejskiego Obszaru Gospodarczego oraz do
                    organizacji międzynarodowych w rozumieniu przepisów RODO.
                </li>
                <li className="my-3">
                    Państwa dane będą przetwarzane w czasie trwania Konkursu oraz po jego zakończeniu w okresie
                    niezbędnym do przekazania Nagród, rozliczeń podatkowych lub wygaśnięcia
                    roszczeń/reklamacji, o ile przepisy nie nakazują wcześniejszego usunięcia danych. Po upływie
                    tych terminów Państwa dane będą usuwane lub poddawane anonimizacji.
                </li>
                <li className="my-3">
                    Przysługuje Państwu prawo dostępu do treści Państwa danych, ich sprostowania, żądania ich
                    usunięcia lub ograniczenia przetwarzania, ich przenoszenia do innego administratora oraz
                    wniesienia sprzeciwu wobec ich przetwarzania. W przypadku potrzeby skorzystania z tych
                    uprawnień prosimy o kontakt wskazany w ust. 2.
                    Jednocześnie informujemy, że żądanie usunięcia Państwa danych jest jednoznaczne z rezygnacją
                    Państwa udziału w Konkursie; w przypadku żądania ograniczenia przetwarzania danych, w
                    zależności od zakresu ograniczenia, zastrzegamy sobie prawo wykluczenia Państwa z udziału w
                    Konkursie.
                </li>
                <li className="my-3">
                    Przysługuje Państwu prawo wniesienia skargi do organu nadzorczego, tj. do Prezesa Urzędu
                    Ochrony Danych Osobowych (ul. Stawki 2, 00-193 Warszawa).
                </li>
                <li className="my-3">
                    Podanie przez Państwa danych osobowych jest dobrowolne, jednakże niezbędne do realizacji
                    celu, w którym są przetwarzane, tj. udziału w Konkursie. Wyrażenie zgody odbywa się poprzez
                    zaznaczenie odpowiednich pól w formularzu zgłoszeniowym. Niepodanie lub podanie częściowo
                    nieprawidłowych danych osobowych uniemożliwia udział w Konkursie.
                </li>
                <li className="my-3">
                    Dalsze przetwarzanie danych osobowych Zwycięzców Konkursu w celach marketingowych może
                    wiązać się z koniecznością wyrażenia dodatkowych zgód na otrzymywanie treści
                    marketingowych.
                </li>
                <li className="my-3">
                    Administrator oświadcza, że poważnie traktuje swoje zobowiązanie do zgodnego z prawem
                    przetwarzania danych osobowych Uczestników Konkursu. Administrator w stosunkach z
                    Uczestnikami będzie działać zgodnie z obowiązującymi przepisami i przyjętymi standardami w
                    zakresie ochrony danych, ze szczególnym uwzględnieniem praw i interesów Uczestników
                    Konkursu.
                </li>
                <li className="my-3">
                    Podane przez Pana/Panią dane osobowe mogą być przetwarzane w sposób zautomatyzowany.
                    Jednak decyzje dotyczące indywidualnej osoby, związane z tym przetwarzaniem nie będą
                    zautomatyzowane.
                </li>
                <li className="my-3">
                    W każdym czasie możecie Państwo dokonać wycofania złożonej wcześniej zgody poprzez
                    złożenie stosownego oświadczenie w formie dokumentowej, umożliwiającej zapoznanie się z
                    jego treścią przez Organizatora, co będzie równoznaczne z zaprzestaniem przetwarzania
                    Państwa danych osobowych. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania
                    danych, którego dokonano na podstawie zgody przed jej wycofaniem.
                </li>
            </ul>

            <div className="mt-5">
                <p className="text-xl font-bold mt-4 text-center">§ 8</p>
                <p className="text-xl font-bold mb-4 text-center">PRAWA AUTORSKIE</p>
            </div>
            <ul className="xl:max-w-4xl mx-auto list-decimal">
                <li className="my-3">
                    Uczestnicy mogą zgłaszać do Konkursu wyłącznie treści własnego autorstwa. Organizator
                    może w każdym czasie dokonać weryfikacji treści, z uwzględnieniem kryterium autorstwa. W
                    razie stwierdzenia naruszeń niniejszego zapisu, Organizator jest uprawniony do wykluczenia
                    Uczestnika dopuszczającego się naruszeń, na każdym etapie Konkursu. Uprawnienie do
                    wykluczenia Uczestnika obejmuje również prawo do pozbawiania Nagród. W takim
                    przypadku Uczestnik ma prawo złożenia reklamacji zgodnie z Regulaminem.
                </li>
                <li className="my-3">
                    Uczestnik ponosi wyłączną odpowiedzialność za naruszenie autorskich praw majątkowych
                    i/lub osobistych praw osób trzecich w związku z przesłanym Zgłoszeniem Konkursowym w
                    ramach zgłoszenia konkursowego. Uczestnik zobowiązuje się zwrócić Organizatorowi
                    wszelkie koszty, jakie poniósł Organizator w związku z naruszeniem praw lub dóbr osób
                    trzecich na skutek eksploatacji wadliwej – w związku z naruszeniem, o którym mowa w
                    zdaniu poprzednim – treści.
                </li>
                <li className="my-3">
                    Uczestnik może zostać wykluczony z Konkursu w każdym czasie w razie stwierdzenia przez
                    Komisję Konkursową, że jego zgłoszenie konkursowe nie spełnia wymogów Regulaminu lub w
                    przypadku zgłoszenia do Organizatora przez osoby trzecie roszczeń związanych ze
                    zgłoszeniem. Uprawnienie do wykluczenia Uczestnika obejmuje również prawo do
                    pozbawiania Nagród. W takim przypadku Uczestnik ma prawo złożenia reklamacji zgodnie z
                    Regulaminem.
                </li>
                <li className="my-3">
                    Uczestnik wyraża zgodę na dokonywanie przez Organizatora lub na jego zlecenie wszelkich
                    zmian, przeróbek i innych modyfikacji Zadania Konkursowego, zgodnie z potrzebami
                    Organizatora.
                </li>
                <li className="my-3">
                    Zadanie Konkursowe przesłane przez Uczestników mogą zostać wykorzystane w kampaniach
                    marketingowych, umieszczone w Internecie lub wykorzystane w katalogach Organizatora lub
                    innych jednostek współpracujących z Organizatorem bez podawania nazwiska autora, na co
                    Uczestnicy konkursu wyrażają zgodę.
                </li>
                <li className="my-3">
                    Dokonanie Zgłoszenia zgodnie z zasadami Regulaminu równoznaczne jest z udzieleniem
                    Organizatorowi niewyłącznej i nieodpłatnej licencji, z prawem do udzielania sublicencji, na
                    korzystanie z nadesłanej pracy bez ograniczeń czasowych i terytorialnych, w celach
                    związanych z organizacją Konkursu oraz celach marketingowych i promocyjnych poprzez:
                    <ul className="pl-5 list-none">
                        <li>
                            a. utrwalenie przy wykorzystaniu wszelkich znanych technik na wszelkich znanych
                            nośnikach, co obejmuje w szczególności utrwalenie techniką analogową, cyfrową i
                            optyczną,
                        </li>
                        <li>
                            b. zwielokrotnienie wszelkim znanymi technikami cyfrowymi, analogowymi i
                            optycznymi,
                        </li>
                        <li>
                            c. publikację na nośnikach wielkoformatowych (zewnętrznych i wewnętrznych),
                        </li>
                        <li>
                            d. wielokrotne publiczne wystawienie,
                        </li>
                        <li>
                            e. eksploatację w Internecie, na stronach www, za pośrednictwem łączy telefonicznych
                            lub satelitarnych, przewodowych lub bezprzewodowych, technik cyfrowych lub
                            analogowych,
                        </li>
                        <li>
                            f. publiczne udostępnianie w taki sposób, aby każdy mógł mieć do niego dostęp w
                            miejscu i w czasie przez siebie wybranym,
                        </li>
                        <li>
                            g. wykorzystywanie w działaniach marketingowych, w tym do reklamy produktów
                            Organizatora,
                        </li>
                        <li>
                            h. wykorzystywanie ich do tworzenia utworów zależnych, a następnie używania tak
                            powstałych utworów w sposób wskazany w pkt. od a) do g),
                        </li>
                        <li>
                            i. bez względu na ilość nadań, emisji, czy wytworzonych egzemplarzy,
                        </li>
                    </ul>
                    oraz zobowiązaniem się do niewykonywania autorskich praw osobistych do Zadania
                    Konkursowego względem Organizatora, w szczególności wyrażeniem zgody na
                    wykorzystywanie Pracy Konkursowej bez podawania imienia, nazwiska lub pseudonimu
                    twórcy.
                </li>
                <li className="my-3">
                    Z chwilą wydania nagród Organizator nabywa własność egzemplarza nagrodzonych prac oraz
                    majątkowe prawa autorskie do tych prac bez ograniczeń czasowych oraz terytorialnych na
                    polach eksploatacji wskazanych w ust. 6 powyżej. Z chwilą określoną w zdaniu
                    poprzedzającym Uczestnik udziela Organizatorowi zezwolenia na wykonywanie zależnych
                    praw autorskich do opracowań pracy konkursowej w rozumieniu art. 2 Prawa autorskiego,
                    dokonanych przez Organizatora lub na jego zlecenie, a także przenosi na Organizatora prawo
                    zezwalania na wykonywanie zależnych praw autorskich.
                </li>
            </ul>
            <div className="mt-5">
                <p className="text-xl font-bold mt-4 text-center">§ 9</p>
                <p className="text-xl font-bold mb-4 text-center">POSTĘPOWANIE REKLAMACYJNE</p>
            </div>
            <ul className="xl:max-w-4xl mx-auto list-decimal">
                <li className="my-3">
                    Wszelkie reklamacje dotyczące sposobu przeprowadzania Konkursu Uczestnicy mogą
                    zgłaszać w formie elektronicznej na adres marketing@dawtona.pl w czasie trwania Konkursu
                    oraz po jego zakończeniu, najpóźniej do dnia 25.02.2022r.
                </li>
                <li className="my-3">
                    Pisemna reklamacja powinna zawierać imię̨, nazwisko, adres do korespondencji i adres e-mail
                    Uczestnika podany przy rejestracji oraz dokładny opis, uzasadnienie reklamacji oraz żądanie
                    określonego zachowania się Organizatora. Reklamacja powinna być́ przesłana na adres
                    podany w § 1 ust. 3 powyżej z tytułem „Reklamacja Konkurs – Moc Pomidora”.
                </li>
                <li className="my-3">
                    Reklamacje rozpatrywane będą̨ pisemnie lub w formie elektronicznej w zależności od
                    sposobu ich zgłoszenia w terminie do 14 dni od daty ich otrzymania. Jeżeli Organizator nie
                    odpowiedział na reklamację w zakreślonym w zdaniu poprzednim reklamacji uznaje się, że
                    została ona uwzględniona w całości.
                </li>
                <li className="my-3">
                    Uczestnik zgłaszający reklamację otrzyma decyzję Organizatora na adres korespondencyjny
                    podany w reklamacji.
                </li>
                <li className="my-3">
                    Postępowanie reklamacyjne jest dobrowolne i nie wyłącza prawa Uczestnika do niezależnego
                    dochodzenia swoich roszczeń na drodze postępowania sądowego.
                </li>
            </ul>

            <div className="mt-5">
                <p className="text-xl font-bold mt-4 text-center">§ 10</p>
                <p className="text-xl font-bold mb-4 text-center">POSTANOWIENIA KOŃCOWE</p>
            </div>
            <ul className="xl:max-w-4xl mx-auto list-decimal">
                <li className="my-3">
                    Regulamin Konkursu dostępny jest w siedzibie Organizatora oraz na stronie
                    www.mocpomidora.pl.
                </li>
                <li className="my-3">
                    Organizator zastrzega sobie prawo zmiany Regulaminu, o ile nie spowoduje to pogorszenia
                    warunków Uczestnika oraz zasad uczestnictwa w Konkursie. W przypadku zmiany
                    Regulaminu, informacja o zmianie oraz nowy Regulamin zostaną̨ zamieszczone na stronie
                    www.mocpomidora.pl w taki sposób, że każdy Uczestnik będzie miał do niego dostęp.
                </li>
                <li className="my-3">
                    W kwestiach nieuregulowanych niniejszym Regulaminem stosuje się̨ przepisy prawa
                    powszechnie obowiązującego, w tym przede wszystkim przepisy Kodeksu cywilnego.
                </li>
                <li className="my-3">
                    O dotrzymaniu terminu nadesłania wiadomości, informacji, korespondencji lub innej
                    przesyłki przewidzianej Regulaminem decydować́ będzie data nadania, chyba że inaczej
                    zastrzeżono w treści niniejszego Regulaminu.
                </li>
                <li className="my-3">
                    Regulamin wchodzi w życie z dniem 13.01.2022.
                </li>
            </ul>
        </div>
    )
}

export default Regulamin