import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Question from "../../components/question/question";
import Reason from "../../components/reason/reason";
import QuestionCounter from "../../components/question_counter/question_counter";
import Loading from "../../components/loading/loading";

import questions_json from '../../assets/question.json'

const randomNumbers = (max) => {
   let numbers = [];
   do {
      let rand = 0;
      do {
         rand = Math.floor(Math.random() * max);
      } while (numbers.includes(rand));
      numbers.push(rand);
   } while (numbers.length < 10);
   return numbers;
};

const Quiz = ({ score, setScore, setAllowScore }) => {
   const navigate = useNavigate();

   const [questions, setQuestions] = useState([]);
   const [answered, setAnswered] = useState(false);
   const [currentIndex, setCurrentIndex] = useState(0);

   useEffect(() => {
      const fetchQuestions = async () => {
         try {
            // const questions = await JSON.parse(questions_json)
            // const { status, data } = await axios("/pytanies");
            // if (status === 200) {
            // if (data.length <= 10) setQuestions(data);
            let random = randomNumbers(questions_json.length);
            let questions = [];
            for (let i = 0; i < random.length; i++) {
               questions.push(questions_json[random[i]]);
            }
            setQuestions(questions);
            setScore(0);
            // pozwol na wejscie na ekran koncowy
            setAllowScore(true);
            // }
         } catch (error) {
            // console.log(error);
         }
      };

      fetchQuestions();
   }, []);

   const nextQuestion = () => {
      if (currentIndex < questions.length - 1) {
         setAnswered(false);
         setCurrentIndex(currentIndex + 1);
      } else {
         navigate("/wynik");
      }
   };

   const answerQuestion = (correct) => {
      setAnswered(true);
      if (answered) return;
      if (correct) setScore(score + 1);
   };

   return (
      <React.Fragment>
         <div className="container mx-auto text-white h-full px-4 3xl:px-0">
            <div className="h-full w-full flex flex-col items-center justify-between py-0 md:py-10 ">
               {questions.length ? (
                  <div className="w-full lg:h-full flex flex-col-reverse lg:flex-row">
                     <div className="w-full lg:w-1/2 h-full">
                        <Question
                           question={questions[currentIndex].question}
                           answers={questions[currentIndex].answers}
                           answered={answered}
                           answerQuestion={answerQuestion}
                           nextQuestion={nextQuestion}
                           currentIndex={currentIndex}
                           reason={questions[currentIndex].reason}
                           reasonImg={questions[currentIndex].image}
                        />
                     </div>
                     <div className="w-full lg:w-1/2 lg:h-full lg:flex flex-col justify-center px-4 md:px-12 hidden">
                        <Reason reason={questions[currentIndex].reason} answered={answered} img={questions[currentIndex].image} />
                     </div>
                  </div>
               ) : (
                  <Loading />
               )}
               {questions.length ? <QuestionCounter questions={questions} currentIndex={currentIndex} /> : null}
            </div>
         </div>
      </React.Fragment>
   );
};

export default Quiz;
