import { useEffect, useState } from "react"
import { Carousel } from "react-responsive-carousel"
import { useLocation, useParams } from "react-router"
import "../../styles/carousel.css";
import SlideRewards from "../../components/game_slides/slide_rewards"
import SlideWelcome from "../../components/game_slides/slide_welcome"

const Slides = () => {
    const [currentSlide, setCurrentSlide] = useState(0)

    const location = useLocation()

    useEffect(() => {
        if (new URLSearchParams(location.search).get('zasady') == 1) setCurrentSlide(1)
    }, [location])

    useEffect(() => {
        setTimeout(() => {
            currentSlide === 0 ? setCurrentSlide(1) : setCurrentSlide(0)
        }, 10000)
    }, [currentSlide])

    return (
        <div className="min-h-screen w-full h-full relative overflow-hidden">
            <Carousel showStatus={false} selectedItem={currentSlide} dynamicHeight={true} emulateTouch={false} showThumbs={false} showArrows={false} preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50} autoPlay={false} interval={100000} className=" min-h-screen w-full text-white text-left">
                <div className="w-full min-h-screen md:h-screen flex flex-col justify-between items-start">
                    {/* <div className=""></div> */}
                    <SlideWelcome />
                </div>
                <div className="w-full min-h-screen md:h-screen">
                    <SlideRewards />
                </div>
            </Carousel>
        </div>
    )
}

export default Slides