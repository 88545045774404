import { useState } from "react"
import { useCookies } from "react-cookie"
import { Link } from "react-router-dom"

const Cookies = () => {
    const [showPopup, togglePopup] = useState(false)
    const [cookies, setCookies] = useCookies()

    useState(() => {
        if (!cookies['agreement']) togglePopup(true)
    }, [cookies['agreement']])

    return (
        <div className="w-full fixed bottom-0 left-0 z-50 px-4">
            <div className="container mx-auto">
                {showPopup && <div className="w-full py-5 px-7 bg-accent bg-opacity-80 rounded-t-3xl">
                    <div className="flex flex-col md:flex-row text-white font-semibold">
                        <div className="text-xs xl:text-sm 3xl:text-base">
                            Informujemy, iż w celu optymalizacji treści dostępnych w naszym serwisie, dostosowania ich
                            do Państwa indywidualnych potrzeb korzystamy z informacji zapisanych za pomocą plików
                            cookies na urządzeniach końcowych użytkowników. Pliki cookies użytkownik może
                            kontrolować za pomocą ustawień swojej przeglądarki internetowej. Dalsze korzystanie z
                            naszego serwisu internetowego, bez zmiany ustawień przeglądarki internetowej oznacza, iż
                            użytkownik akceptuje stosowanie plików cookies.
                            <br />
                            Czytaj więcej w <Link to="/polityka-prywatnosci" className="underline">polityka prywatności.</Link>
                        </div>
                        <div className="flex-shrink-0 md:ml-16 text-center mt-6 md:mt-0">
                            <Link to="/polityka-prywatnosci" className="rounded-full bg-white text-accent block px-5 py-1 md:py-2 uppercase md:text-lg font-bold">
                                Polityka prywatności
                            </Link>
                            <button onClick={
                                () => {
                                    setCookies('agreement', true, { path: '/', expires: new Date(Date.now + 1000 * 60 * 60 * 24 * 365 * 5) })
                                    togglePopup(false)
                                }
                            } className="rounded-full bg-white text-accent block px-5 py-1 md:py-2 uppercase md:text-lg font-bold w-full mt-3">
                                AKCEPTUJĘ
                            </button>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default Cookies