import { useEffect, useState } from "react"

const Answer = ({ answerQuestion, answer, correct, answered }) => {
    const [isWrong, setIsWrong] = useState(false)
    const [clicked, setClicked] = useState(false)

    // po przejsciu do nowego pytania - zresetuj
    useEffect(() => {
        if (!answered) {
            setIsWrong(false)
            setClicked(false)
        }
    }, [answered])

    const answerClicked = () => {
        if (answered) return
        answerQuestion(correct)
        setClicked(true)
        if (!correct) setIsWrong(true)
    }

    return (
        <button
            className={`w-full py-2 flex items-center text-lg 2xl:text-xl 3xl:text-2xl 2xl:mb-4 ${answered && correct && 'text-green-500'} ${answered && isWrong && 'text-accent line-through decoration-white'}`}
            onClick={() => answerClicked()}>
            <div className={`w-5 h-5 ${clicked && 'bg-accent'} bg-white mr-4 rounded-full border-2 border-white flex-shrink-0`}></div>
            <span className={`break-words block whitespace-pre-line text-left ${answered && correct && 'pulse-answer'}`}>
                {answer}
            </span>
        </button>
    )
}

export default Answer