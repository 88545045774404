import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import logo_wosp from "../../images/logo_wosp.png";
import MobileMenu from "./mobileMenu";

const Nav = ({ withPadding = false }) => {
   const [menuActive, setMenuActive] = useState(false);
   const toggleMenu = () => {
      setMenuActive(!menuActive);
   };
   return (
      <div className="w-full h-16 2xl:h-20 py-2 text-white px-4 3xl:px-0 relative z-40 pt-8 sm:pt-0">
         <MobileMenu toggleMenu={toggleMenu} menuActive={menuActive} />
         <div className="container mx-auto h-full w-full">
            <div className="flex flex-col md:flex-row items-end justify-between w-full h-full">
               <div className="flex justify-between flex-row items-center w-full h-full">
                  <div className="h-14 sm:h-12 md:h-full flex w-full items-start mb-2 md:mb-0 md:pt-2">
                     <Link to="/start" className="block h-full"><img src={logo} alt="dawtona" className="h-full mr-5" /></Link>
                     <Link to="/start" className="block h-full"><img src={logo_wosp} alt="wośp" className="h-full" /></Link>
                  </div>
                  <div className="mobile-visible relative z-50">
                     <div className={`toggler ${menuActive && 'open'}`} onClick={toggleMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                     </div>
                  </div>
               </div>

               <div className={`h-full mt-3 md:mt-0 ${withPadding && "xl:pr-72 3xl:pr-48"}`}>
                  <div className="flex uppercase justify-around items-end h-full 2xl:text-xl 3xl:text-2xl mobile-hidden">
                     {/* <Link to="/gra/quiz">Zasady</Link> */}
                     <Link to="/start?zasady=1" className="mx-10">
                        Nagrody
                     </Link>
                     <a href="mailto:pomoc@mocpomidora.pl">Kontakt</a>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Nav;
