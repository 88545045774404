const Policy = () => {
    return (
        <div className="container mx-auto px-4 lg:px-0 text-white">
            <ul className="xl:max-w-4xl mx-auto">
                <p className="text-xl font-bold mb-4 mt-4">Ochrona danych osobowych</p>
                <li className="my-3">
                    1. Administratorem Państwa danych osobowych jest Dawtona sp. z o.o.,
                    z  siedzibą  w  Błoniu,  ul.  Bieniewcka  52,  05-870  Błonie,  wpisana  do  Rejestru  Przedsiębiorców
                    Krajowego  Rejestru  Sądowego  prowadzonego  przez  Sąd  Rejonowy  dla  m.  st.  Warszawy  w
                    Warszawie,  XIV  Wydział  Gospodarczy  Krajowego  Rejestru  Sądowego,  pod  numerem  KRS
                    0000652355, NIP 1182135917, REGON 366238956, o kapitale zakładowym w wysokości 304 960
                    000,00 zł (zwana dalej „Organizatorem”) (dalej „Dawtona”).
                </li>
                <li className="my-3">
                    2. We  wszelkich  sprawach  związanych  z  przetwarzaniem  Państwa  danych  osobowych  przez
                    Dawtona prosimy o kontakt pod adresem email: iod@dawtona.pl na adres wskazany w ust. 1 z
                    dopiskiem „dane osobowe”.
                </li>
                <li className="my-3">
                    3. W związku z zapisem do newsletteru będą przetwarzane następujące Państwa dane:
                    <ul className="pl-6">
                        <li>a. Adres e-mail Uczestnika podany w formularzu;</li>
                    </ul>

                </li>
                <li className="my-3">
                    4. Dane osobowe, będą przetwarzane w celach:
                    <ul className="pl-6">
                        <li>a. Zapisu do newslettera (na podstawie wyrażonej zgody);</li>
                        <li>b. Wysyłki newslettera w wersji elektronicznej na podany adres e-mail.</li>
                    </ul>
                </li>
                <li className="my-3">
                    5. Z  uwagi  na  zapewnienie  odpowiedniej  organizacji  w  bieżących  sprawach  dotyczących  wysyłki
                    newslettera odbiorcą Państwa danych osobowych mogą być:
                    <ul className="pl-6">
                        <li> a. agencje  marketingowe  będące  działające  na  zlecenie  Dawtona  na  podstawie
                            stosownych umów powierzenia lub przepisów prawa;</li>
                        <li> b.   dostawcy  usług  prawnych  i  doradczych  wspierających  Dawtona  w  dochodzeniu
                            należytych roszczeń (w szczególności kancelarie prawne, firmy windykacyjne);</li>
                        <li>  c. dostawcy  usług  zaopatrujących  Dawtona  w  rozwiązania  techniczne  oraz
                            organizacyjne,  wspierających  procesy  biznesowe  (w  szczególności  dostawcy  usług
                            informatycznych)  działające  na  zlecenie  Dawtona  na  podstawie  stosownych  umów
                            lub przepisów prawa.</li>
                    </ul>



                </li>
                <li className="my-3">
                    6. Państwa dane nie będą przekazywane poza teren Europejskiego Obszaru Gospodarczego oraz do
                    organizacji międzynarodowych w rozumieniu przepisów RODO.
                </li>
                <li className="my-3">
                    7. Państwa  dane  będą  przetwarzane  w  okresie  od  3.12.2021  r.  do  31.01.2022  r.  a  w  przypadku
                    składania  reklamacji  lub  żądań  w  czasie  niezbędnym  do  ich  rozpatrzenia.    Po  upływie  tego
                    terminu Państwa dane zostaną usunięte.
                </li>
                <li className="my-3">
                    8. Przysługuje  Państwu  prawo  dostępu  do  treści  Państwa  danych,  ich  sprostowania,  żądania  ich
                    usunięcia  lub  ograniczenia  przetwarzania,  ich  przenoszenia  do  innego  administratora  oraz
                    wniesienia  sprzeciwu  wobec  ich  przetwarzania.  W  przypadku  potrzeby  skorzystania  z  tych
                    uprawnień prosimy o kontakt wskazany w ust. 2.
                    Jednocześnie informujemy, że żądanie usunięcia Państwa danych jest jednoznaczne z rezygnacją
                    Państwa z otrzymania newslettera; w przypadku żądania ograniczenia przetwarzania danych, w
                    zależności  od  zakresu  ograniczenia,  zastrzegamy  sobie  prawo  wykluczenia  Państwa  z  listy
                    odbiorców newslettera..
                </li>
                <li className="my-3">
                    9. Przysługuje  Państwu  prawo  wniesienia  skargi  do  organu  nadzorczego,  tj.  do  Prezesa  Urzędu
                    Ochrony Danych Osobowych (ul. Stawki 2, 00-193 Warszawa).
                </li>
                <li className="my-3">
                    10. Podanie  przez  Państwa  danych  osobowych  jest  dobrowolne,  jednakże  niezbędne  do  realizacji
                    celu,  w  którym  są  przetwarzane,  tj.  otrzymanie  newslettera.  Wyrażenie  zgody  odbywa  się
                    poprzez  zaznaczenie  odpowiednich  pól  w  formularzu  zgłoszeniowym.  Niepodanie  lub  podanie
                    częściowo nieprawidłowych danych osobowych może uniemożliwić otrzymanie newslettera.
                </li>
                <li className="my-3">
                    11. Administrator  oświadcza,  że  poważnie  traktuje  swoje  zobowiązanie  do  zgodnego  z  prawem
                    przetwarzania  danych  osobowych  Uczestników  Konkursu  (w  tym  wizerunku).  Administrator  w
                    stosunkach  z  Uczestnikami  będzie  działać  zgodnie  z  obowiązującymi  przepisami  i  przyjętymi
                    standardami  w  zakresie  ochrony  danych,  ze  szczególnym  uwzględnieniem  praw  i  interesów
                    Uczestników Konkursu.
                </li>
                <li className="my-3">
                    12.  Podane przez Pana/Panią dane osobowe mogą być przetwarzane w sposób zautomatyzowany.
                    Jednak  decyzje  dotyczące  indywidualnej  osoby,  związane  z  tym  przetwarzaniem  nie  będą
                    zautomatyzowane.
                </li>
                <li className="my-3">
                    13. W  każdym  czasie  możecie  Państwo  dokonać  wycofania  złożonej  wcześniej  zgody  poprzez
                    złożenie  stosownego  oświadczenie  w  formie  dokumentowej,  umożliwiającej  zapoznanie  się  z
                    jego  treścią  przez  Organizatora,  co  będzie  równoznaczne  z  zaprzestaniem  przetwarzania
                    Państwa danych osobowych. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania
                    danych, którego dokonano na podstawie zgody przed jej wycofaniem.
                </li>
            </ul>
        </div>
    )
}

export default Policy