import React from "react"
import { Link } from "react-router-dom"
import Nav from "../nav/nav"
import Footer from "../footer/footer"

import projector from '../../images/projector.png'
import oculus from '../../images/oculus.png'
import set from '../../images/set.png'
import tomato_full from '../../images/tomato_full.png'
import doodle from '../../images/doodle_right.png'
import side_doodle from '../../images/side_doodle.jpg'
import question_doodle from '../../images/question_doodle.png'

const SlideRewards = () => {
    return (
        <div className="w-screen min-h-screen md:min-h-0 h-full bg-opacity-40 relative overflow-hidden flex flex-col justify-between ">
            <Nav />
            <div className="container mx-auto h-full flex flex-col justify-between items-start pb-12 px-4 3xl:px-0 pt-10 lg:pt-0">
                <h2 className="heading-main">Nagrody</h2>
                <div className="w-full h-full relative flex flex-col items-center ">
                    <div className="h-1/3 lg:h-1/2 w-full flex items-start justify-center">
                        <div className="h-full flex items-center">
                            <img src={oculus} alt="" className="mt-10 md:mt-0 w-44 md:w-56 2xl:w-64 3xl:w-auto 4xl:h-5/6" />
                        </div>
                    </div>
                    <div className="h-2/3 lg:h-1/2 w-full flex flex-col md:flex-row items-center justify-around 3xl:justify-between py-4 sm:py-10 md:py-20 lg:py-0 ">
                        <div className="md:-mt-20 2xl:-mt-28 md:self-end lg:self-auto h-full flex items-center">
                            <img src={projector} alt="" className="w-56 md:w-72 3xl:w-auto 4xl:h-3/4" />
                        </div>
                        <div className="mt-12 md:mt-0 md:self-start lg:self-auto h-full flex items-center">
                            <img src={set} alt="" className="w-56 md:w-72 3xl:w-auto 4xl:h-1/2" />
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-col items-center">
                    <h2 className="heading-main normal-case text-center text-2xl md:text-3xl 2xl:text-4xl">
                        Weź udział w Quizie!
                    </h2>
                    <Link to="/gra/quiz" className="btn-primary mx-auto mt-3 2xl:mt-4 w-60 sm:w-72 2xl:w-96 text-center">GRAM</Link>
                </div>
            </div>
            <Footer />

            <img src={side_doodle} alt="doodle" className="absolute -right-4 xl:right-0 3xl:right-0 -bottom-12 z-0 w-28 3xl:w-auto hidden md:block" />
            <img src={tomato_full} alt="tomato top center" className="absolute transform -rotate-45 lg:rotate-0 -right-14 md:right-1/2 lg:right-1/3 top-1/4 md:top-3 3xl:-top-0 z-10 w-24 2xl:w-28 3xl:w-32 block" />
            <img src={tomato_full} alt="tomato left bottom" className="absolute transform -rotate-90 lg:rotate-0 -left-16 md:left-5 bottom-1/2 sm:bottom-1/3 lg:bottom-12 3xl:bottom-20 z-10 w-28 3xl:w-auto block" />
            <img src={question_doodle} alt="doodle" className="absolute -right-48 3xl:right-0 top-64 transform rotate-180 w-64 z-0 block md:hidden" />
            <img src={question_doodle} alt="doodle" className="absolute -left-60 transform -rotate-12 top-64 w-72 z-0 block md:hidden" />
        </div>
    )
}

export default SlideRewards