import React from "react";
import { Link } from "react-router-dom";
const Mobilemenu = ({ toggleMenu, menuActive }) => {
   return (
      <React.Fragment>
         <div className={`mobile-menu ${menuActive && 'open'} absolute bg-black text-white  px-6 py-24 top-0`}>
            {/* <div className="absolute toggler toggler-open py-6 mt-4 pr-1" onClick={toggleMenu}>
                  <span></span>
                  <span></span>
               </div> */}
            <div className="flex flex-col items-end gap-12 uppercase text-xl">
               <Link to="/gra/quiz" onClick={toggleMenu}>
                  Zasady
               </Link>
               <Link to="/start?zasady=1" onClick={toggleMenu}>
                  Nagrody
               </Link>
               <a href="mailto:pomoc@mocpomidora.pl">Kontakt</a>
            </div>
         </div>

      </React.Fragment>
   );
};

export default Mobilemenu;
