import Answer from "./answer/answer";
import Reason from "../reason/reason";
const Question = ({ question, answers, answered, answerQuestion, nextQuestion, currentIndex, reason, reasonImg }) => {
   return (
      <div className="w-full h-full relative z-30">
         <h2 className="heading-main question-number text-5xl 2xl:text-6xl 3xl:text-7xl">#{currentIndex + 1}</h2>
         <div className="block lg:hidden">
            <Reason reason={reason} answered={answered} img={reasonImg} />
         </div>
         <div className="h-full lg:pb-40 ml-auto flex flex-col justify-center pl-4 md:pl-44 lg:pl-40">
            <div className="flex-shrink-0">
               <h3 className="font-black text-xl 2xl:text-2xl 3xl:text-3xl mt-2 md:mt-6 normal-case">{question}</h3>

               <div className="mt-2 md:mt-6 md:w-3/4 whitespace-nowrap">
                  {answers.map((a) => {
                     return <Answer key={a.id} id={a.id} answerQuestion={answerQuestion} answer={a.answer} correct={a.correct} answered={answered} />;
                  })}
               </div>
            </div>
            <div className="mt-5 h-12 lg:h-20">
               {answered && (
                  <button onClick={nextQuestion} className="btn-primary uppercase mt-0 inline-block ml-0">
                     Idź dalej
                  </button>
               )}
            </div>
         </div>
      </div>
   );
};

export default Question;
