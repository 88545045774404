import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import { useState } from "react"
import Checkbox from "../checkbox/checkbox"
import Input from "../input/input"
import * as Yup from 'yup'
import { Form, Formik, Field, ErrorMessage } from "formik"

export const formSchema = Yup.object().shape({
    nick: Yup.string().required("Wpisz imię"),
    email: Yup.string().email("Wpisz poprawny adres email").required("Wpisz email"),
    answer: Yup.string().required("Wpisz odpowiedź"),
    dataAgreement: Yup.bool().oneOf([true], "Zaakceptuj zgodę"),
    informationAgreement: Yup.bool().oneOf([true], "Zaakceptuj zgodę")
})

const SubmitForm = () => {
    const navigate = useNavigate()
    const [emailUsed, setEmailUsed] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    return (
        <div className="mt-3 pb-20">
            <div className="w-full mt-5">
                <Formik
                    validationSchema={formSchema}
                    initialValues={
                        {
                            nick: '',
                            email: '',
                            answer: '',
                            dataAgreement: false,
                            informationAgreement: false
                        }
                    }
                    onSubmit={async (values) => {
                        setEmailUsed(false)
                        setIsLoading(true)
                        try {
                            const { status } = await axios.post('/submissions', {
                                ...values
                            })
                            // console.log(status)
                            if (status === 200) {
                                navigate('/koniec')
                                return;
                            }
                        } catch (error) {
                            const err = { ...error }
                            // console.log(err)
                            if (err.response.status === 400) setEmailUsed(true)
                        }
                        setIsLoading(false)
                    }}
                >
                    <Form>
                        <div className="max-w-xl mx-auto">
                            <p className="uppercase font-medium mb-2 lg:text-xl 2xl:text-2xl">Twoja KREATYWNA  odpowiedź na pytanie konkursowe</p>
                            <Field name="answer" id="answer" as={(props) => <textarea cols="30" {...props} rows="4" className="resize-none w-full p-3 font-medium 2xl:text-xl text-black focus:outline-none"></textarea>} />
                            <ErrorMessage name="answer">{msg => <div className="text-accent text-sm text-left">{msg}</div>}</ErrorMessage>
                        </div>
                        <p className="uppercase md:mb-4 text-3xl md:text-4xl font-bold mt-4 md:mt-10 2xl:mt-20">Zapisz się i zawalcz o nagrody </p>
                        <div className="w-full">
                            <div className="flex flex-col md:flex-row justify-center">
                                <Input label="Twoje imię:" name="nick" id="name" />
                                <Input label="Twój email:" name="email" id="email" />
                            </div>
                            <div className="flex flex-col md:flex-row justify-center md:px-10 lg:px-40">
                                <div className="mt-4 mr-5">
                                    <Checkbox name="dataAgreement" text={'Wyrażam zgodę na przetwarzanie moich danych osobowych na zasadach określonych w'} url={'/regulamin'} />
                                </div>
                                <div className="mt-4">
                                    <Checkbox name="informationAgreement" text={'Zapoznałem się z klauzulą informacyjną znajdującą się w'} url={'/regulamin'} />
                                </div>
                            </div>
                        </div>
                        <div className="max-w-xs mx-auto mt-12">
                            <button className={`btn-primary ml-0 px-10 mt-0 w-full duration-200 ${isLoading && 'btn-disabled'}`} disabled={isLoading} type="submit">Wyślij</button>
                        </div>
                    </Form>
                </Formik>
                {emailUsed && <div className="text-accent mt-1">Wziąłęś już udział w konkursie</div>}
            </div>
        </div>
    )
}

export default SubmitForm
