export function deOrphan(text) {
    const arr = text.split(' ')
    let joined = '';

    arr.forEach((e, index) => {
        if (e.length < 3) {
            arr[index] = '' + e + '&nbsp;'
            joined += arr[index]
        } else {
            joined += e + ' '
        }
    })

    return joined;
}