import { useState, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router";
import Rules from "./pages/rules/rules";
import FinishScreen from "./pages/finish_screen/sinish_screen";
import Game from "./pages/game/game";
// import Main from "./pages/main";
import Policy from "./pages/policy";
import Potwierdzenie from "./pages/potwierdzenie";
import Quiz from "./pages/quiz/quiz";
import Score from "./pages/score/score";
import Slides from "./pages/slides/slides";

import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import Regulamin from "./pages/regulamin";
import Polityka from "./pages/polityka";
import Cookies from "./components/cookies/cookies";

function App() {
   const tagManagerArgs = {
      gtmId: "GTM-W7JJ2RN",
   };
   const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
   };

   useEffect(() => {
      ReactPixel.init("782987466432721", options);
      ReactPixel.pageView(); // For tracking page view
      ReactGA.initialize("G-341RG4G92P");
      ReactGA.pageview(window.location.pathname + window.location.search);
      TagManager.initialize(tagManagerArgs);
   }, []);

   const [score, setScore] = useState(0);
   const [allowScore, setAllowScore] = useState(false);
   return (
      <div className="relative h-full w-full bg-black lg:overflow-hidden ">
         <Routes>
            {/* <Route path="/" element={<Main />} /> */}
            <Route path="/start" element={<Slides />} />
            <Route path="/gra" element={<Game />}>
               {/* <Route path="zasady" element={<Rules />} /> */}
               <Route path="quiz" element={<Quiz score={score} setScore={setScore} setAllowScore={setAllowScore} />} />
               <Route path="*" element={<Navigate to="/start" />} />
            </Route>
            <Route path="/wynik" element={<Score score={score} setScore={setScore} allowScore={allowScore} />} />
            <Route path="/koniec" element={<FinishScreen allowFinish={allowScore} />} />
            <Route path="/potwierdzenie" element={<Potwierdzenie />} />
            <Route path="/ochrona-danych-osobowych" element={<Policy />} />
            <Route path="/regulamin" element={<Regulamin />} />
            <Route path="/polityka-prywatnosci" element={<Polityka />} />
            <Route path="*" element={<Navigate to="/start" />} />
         </Routes>
         <Cookies />
      </div>
   );
}

export default App;
