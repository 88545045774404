import { useEffect, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Nav from "../../components/nav/nav";
import SubmitForm from "../../components/submit_form/submit_form";
import Tiles from "../../components/tiles/tiles"

import tomato_full from '../../images/tomato_full.png'
import doodle from '../../images/doodle_right.png'
import question_doodle from '../../images/question_doodle.png'

const Score = ({ score, setScore, allowScore }) => {
    const ref = useRef(null)
    const navigate = useNavigate()
    const [result, setResult] = useState(score);
    const [question, setQuestion] = useState('')

    const formatQuestion = (question) => {
        let indexes = []
        question = question.split('')
        question.forEach((e, index) => e !== '*' && indexes.push(index))
        const replacements = []
        while (replacements.length < 11) {
            let random
            do {
                random = Math.floor(Math.random() * indexes.length);
            } while (replacements.includes(indexes[random]) || question[indexes[random]] === ' ');
            // dodac to jak spacji nie chowac
            // || question[indexes[random]] === ' '
            replacements.push(indexes[random])
        }
        replacements.forEach(r => {
            question[r] = '*'
        })
        setQuestion(question.join(''))
    }

    useEffect(() => {
        formatQuestion("Dlaczego ****ona ** najlepszy *** pomidorowy")
        // if (!allowScore) navigate('/start')
    }, [])

    // useEffect(() => {
    //     if (result <= 0 && ref) window.scrollTo({ top: ref.current.getBoundingClientRect().top, behavior: 'smooth' })
    // }, [result])


    const formatAnswers = (points) => {
        if (points === 0) return 'pytań'
        else if (points === 1) return 'pytanie'
        else if (points === 2 || points === 3 || points === 4) return 'pytania'
        else if (points >= 5) return 'pytań'
    }
    // const formatPoint = (points) => {
    //     if (points === 0) return 'punktów'
    //     else if (points === 1) return 'punkt'
    //     else if (points === 2 || points === 3 || points === 4) return 'punkty'
    //     else if (points >= 5) return 'punktów'
    // }
    // const formatTomato = (points) => {
    //     if (points === 0) return 'pomidorów'
    //     else if (points === 1) return 'pomidor'
    //     else if (points === 2 || points === 3 || points === 4) return 'pomidory'
    //     else if (points >= 5) return 'pomidorów'
    // }

    return (
        <div className="min-h-screen flex flex-col justify-between bg-black relative overflow-hidden">
            <Nav />
            <div className="flex-1 lg:relative z-20 px-4 2xl:px-0 flex flex-col">
                <div className="h-full w-full flex-1 flex flex-col justify-center items-center">
                    <div className="container mx-auto text-white text-center">
                        <h2 className="text-xl md:text-2xl 2xl:text-3xl 3xl:text-4xl font-bold">Gratulacje, odpowiedziałeś poprawnie na <span className="text-accent">{score}</span> {formatAnswers(score)}!
                        </h2>
                        {/* <h2 className="text-lg md:text-xl 2xl:text-2xl 3xl:text-3xl font-medium">Zdobyłeś <span className="text-accent">{score}</span>  {formatPoint(score)} co oznacza, że możesz odsłonić <span className="text-accent">{score}</span> {formatTomato(score)} w pytaniu konkursowym. </h2>

                        <h2 className="heading-main my-5">Weź udział w konkursie</h2> */}
                        {/* <div className="flex flex-col md:flex-row items-center justify-center my-5 ">
                            <h2 className="text-lg lg:text-xl 2xl:text-2xl 3xl:text-3xl font-medium">Jeżeli uważasz, że masz niewystarczającą ilość pomidorów</h2>
                            <Link to="/gra/quiz" className="btn-primary inline-block ml-0 md:ml-5 mt-3 md:mt-0 text-lg lg:text-xl 2xl:text-2xl px-3 lg:px-6 2xl:px-10">Zagraj jeszcze raz</Link>
                        </div> */}
                        <div className="mt-7">
                            <Link to="/gra/quiz" className="btn-primary inline-block ml-0 md:ml-5 mt-3 md:mt-0 text-lg lg:text-xl 2xl:text-2xl px-3 lg:px-6 2xl:px-10">Zagraj jeszcze raz</Link>
                        </div>
                        {/* <h2 className="heading-main mt-10 2xl:mt-16">Pytanie konkursowe</h2>
                        <h3 className="mt-3 text-lg lg:text-xl 2xl:text-2xl 3xl:text-3xl font-medium">
                            Wybierz pomidora, kliknij, a odsłonisz jedną literę
                        </h3>
                        <div ref={ref} id="form"></div>
                        <Tiles score={result} setScore={setResult} question={question.split('')} />
                        <p className="uppercase text-sm md:text-base 2xl:text-lg font-medium text-right mt-2 md:pr-24 3xl:pr-0">Pomidory do wykorzystania: <span className="text-accent">{result}</span></p>
                        <SubmitForm /> */}
                    </div >
                </div>
            </div>
            <Footer />

            <img src={doodle} alt="doodle" className="absolute -right-4 3xl:right-0 top-12 z-0 w-28 3xl:w-auto hidden md:block" />

            <img src={tomato_full} alt="tomato top center" className="absolute transform -rotate-45 lg:rotate-0 -right-14 md:right-1/2 lg:right-1/3 top-1/4 md:top-3 3xl:top-0 z-10 w-24 2xl:w-28 3xl:w-32 block" />
            <img src={tomato_full} alt="tomato left bottom" className="absolute transform -rotate-90 lg:rotate-0 -left-16 md:left-5 top-1/2 z-10 w-28 3xl:w-auto block" />

            <img src={question_doodle} alt="doodle" className="absolute -right-48 3xl:right-0 top-1/4 transform rotate-180 w-64 z-0 block md:hidden" />
            <img src={question_doodle} alt="doodle" className="absolute -left-60 transform -rotate-12 top-1/2 w-72 z-0 block md:hidden" />
        </div>

    )
}

export default Score