import React from "react"
import { Link } from "react-router-dom"

import juice from '../../images/doodle_juice_cut.png'
import Footer from "../footer/footer"
import Nav from "../nav/nav"
import question_doodle from '../../images/question_doodle.png'
import { deOrphan } from "../../utils/deorphan"

const SlideWelcome = () => {
    return (
        <div className="w-full h-full min-h-screen md:min-h-0 relative lg:overflow-y-hidden overflow-x-hidden flex flex-col justify-between xl:pl-20 3xl:pl-0">
            <Nav withPadding={true} />
            <div className="container mx-auto pb-16 px-4 3xl:px-0 pt-10 lg:pt-0">
                <div className="relative z-20">
                    <h1 className="text-3xl sm:text-4xl xl:text-4xl 3xl:text-5xl font-bold mt-4 uppercase leading-none">
                        DOWIEDZ SIĘ DLACZEGO <br />
                        SOK POMIDOROWY MA MOC
                    </h1>
                    <div className="text-base 3xl:text-xl my-12 leading-none 3xl:leading-tight">
                        <div dangerouslySetInnerHTML={{ __html: deOrphan('Do tej pory Orkiestra zebrała i wydała na wsparcie polskiej medycyny <br className="hidden md:block"/>ponad 1,5 mld zł i kupiła ponad 67 300 urządzeń. <br className="hidden md:block" />') }}>
                        </div>
                        <div className="mt-3"></div>
                        <div dangerouslySetInnerHTML={{ __html: deOrphan('W tym roku Dawtona gra razem z WOŚP by zapewnić najwyższe <br className="hidden md:block" />standardy diagnostyki i leczenia wzroku u dzieci. <br />Gramy razem, bo sok pomidorowy ma moc! Pomoże ci zadbać o twój wzrok i zdrowie.') }}>
                        </div>
                    </div>
                    <div className="w-1/2 md:w-full">
                        <h2 className="heading-main normal-case block">
                            Weź udział w Quizie!
                        </h2>
                        <div className="flex">
                            <Link to="/gra/quiz" className="btn-primary w-60 sm:w-72 2xl:w-96 text-center">GRAM</Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <img src={juice} alt="" className="absolute -right-10 md:-right-24 xl:-right-24 3xl:-right-32 bottom-4 md:-bottom-44 xl:-bottom-32 2xl:-bottom-40 3xl:-bottom-48 z-0 block w-64 md:w-3/4 xl:w-45/100 3xl:w-45/100" />
            <img src={question_doodle} alt="doodle" className="absolute -right-52 3xl:right-0 top-12 transform rotate-180 w-64 z-0 block md:hidden" />
        </div>
    )
}

export default SlideWelcome