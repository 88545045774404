import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import tomato_slice from '../../images/tomato_slice.png'
import tomato_full from '../../images/tomato_full.png'
import tomato from '../../images/tomato.png'
import doodle from '../../images/question_doodle.png'
import doodle_large from '../../images/doodle_large.png'
import doodle_left from '../../images/doodle_left.png'
import question_doodle from '../../images/question_doodle.png'
import moc_pomidora from '../../images/moc_pomidora.jpg'
import juice from '../../images/juice.png'
import Footer from "../../components/footer/footer";
import Nav from "../../components/nav/nav";

const FinishScreen = ({ allowFinish }) => {
    // const navigate = useNavigate()

    // useEffect(() => {
    //     if (!allowFinish) navigate('/start')
    // }, [])

    return (
        <div className="min-h-screen flex flex-col justify-between bg-black relative overflow-hidden">
            <Nav />
            <div className="flex-1 lg:relative z-20 px-4 2xl:px-0">
                <div className="h-full w-full">
                    <div className="container mx-auto h-full text-white text-center md:pr-12 2xl:pr-32 3xl:pr-12">
                        <div className="w-full lg:h-full flex flex-col lg:flex-row">
                            <div className="w-full lg:w-1/2 h-full pt-14 lg:pt-44 3xl:pt-60 lg:pl-40 3xl:pl-0">
                                <h2 className="heading-main">Dziękujemy,</h2>
                                <h3 className="text-2xl 2xl:text-3xl font-black uppercase mt-2">Twoje zgłoszenie zostało wysłane!</h3>
                                <h3 className="text-2xl 2xl:text-3xl font-black uppercase mt-2">Chcesz zwiększyć szansę na wygraną?</h3>
                                <div className="flex flex-col md:flex-row items-center justify-center my-5 ">
                                    <h2 className="text-lg 2xl:text-xl 3xl:text-3xl font-medium">Nic prostszego, po prostu</h2>
                                    <Link to="/gra/quiz" className="btn-primary inline-block ml-0 md:ml-5 mt-3 md:mt-0 text-lg lg:text-lg 2xl:text-xl px-3 lg:px-6 2xl:px-4">Zagraj jeszcze raz</Link>
                                </div>
                                {/* <Link to="/gra/zasady" className="btn-primary">Zagraj jeszcze raz</Link> */}
                            </div>
                            <div className="w-full lg:w-1/2 lg:h-full flex flex-col justify-center md:px-12 pt-24 lg:pt-10 3xl:pt-24 pb-12 md:pb-0">
                                <div className="w-full md:px-32 lg:px-10 xl:px-24 2xl:px-28 relative">
                                    <div className='relative'>
                                        <div className="reason-container z-10">
                                            <div className="absolute left-0 top-0 w-full h-full bg-black border-8 border-accent">
                                                <div className="relative w-full h-full pt-2 2xl:pt-5 pl-5 pr-20 pb-8 2xl:pb-12 flex flex-col justify-between">
                                                    <h3 className="text-xl 2xl:text-2xl 3xl:text-3xl font-black uppercase mt-2 text-left">DOWIEDZ SIĘ <br />
                                                        DLACZEGO <br />
                                                        SOK POMIDOROWY <br />
                                                        MA MOC</h3>
                                                    <h3 className="text-xl 2xl:text-2xl 3xl:text-3xl uppercase mt-2 text-left">Weź udział <br />
                                                        w quizie, <br />
                                                        <span className="text-accent font-medium">
                                                            wygraj super <br />
                                                            nagrody!</span>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <img src={tomato_slice} alt="tomato slice" className='absolute -left-5 md:-left-12 -bottom-5 md:-bottom-6 z-20 transform rotate-75 w-20 2xl:w-24 3xl:w-28' />
                                        <img src={tomato} alt="tomato slice" className='absolute right-20 2xl:right-24 -bottom-2 md:-bottom-6 z-20 w-16 md:w-20 2xl:w-24 3xl:w-28' />
                                        <img src={tomato_full} alt="tomato full" className='absolute -right-20 2xl:-right-28 bottom-3 z-0 w-14 md:w-24 3xl:w-32' />
                                        <img src={doodle} alt="tomato slice" className='absolute -right-24 2xl:-right-36 3xl:-right-48 -top-8 2xl:-top-12 3xl:-top-20 z-0 w-44 md:w-48 2xl:w-60 3xl:w-auto' />
                                        <img src={juice} alt="tomato slice" className='absolute -right-4 md:-right-12 2xl:-right-20 2xl:-right-30 -bottom-8 z-20 w-32 md:w-40 2xl:w-48 3xl:w-auto' />
                                    </div>
                                    <div className="mt-10 2xl:mt-10 ">
                                        <h3 className="text-xl 2xl:text-2xl 3xl:text-3xl font-bold text-left">
                                            <div className="leading-none">
                                                Pobierz i udostępnij <br />
                                                w Social Mediach z #MocPomidora
                                            </div>
                                        </h3>
                                        <div className="w-full flex justify-start">
                                            <a href={moc_pomidora} download className="btn-primary mt-4 ">Pobierz</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div>
            </div>
            <Footer />

            <img src={doodle_large} alt="doodle" className="absolute -right-4 3xl:-right-8 top-2/3 lg:top-56 3xl:top-48 z-0 w-44 2xl:w-60 3xl:w-80 hidden md:block" />
            <img src={doodle} alt="tomato left bottom" className="absolute right-36 2xl:right-40 3xl:right-28 -bottom-40 2xl:-bottom-36 3xl:-bottom-80 z-0 w-40 3xl:w-auto hidden md:block" />
            <img src={doodle_left} alt="tomato left bottom" className="absolute left-0 bottom-0 z-0 w-20 2xl:w-24 3xl:w-auto hidden md:block" />

            <img src={tomato_full} alt="tomato top center" className="absolute transform -rotate-45 lg:rotate-0 -right-14 md:right-1/2 lg:right-1/3 top-12 md:top-3 3xl:top-0 z-10 w-24 2xl:w-28 3xl:w-32 block" />
            <img src={question_doodle} alt="doodle" className="absolute -right-48 3xl:right-0 top-12 transform rotate-180 w-64 z-0 block md:hidden" />
        </div>

    )
}

export default FinishScreen