import logo from "../images/logo.png";
import wosp_logo from "../images/logo_wosp.png";
import doodle from "../images/doodle.jpg";
import juice from "../images/juice.png";
import { Link } from "react-router-dom";

function Potwierdzenie() {
    return (
        <div className="w-full min-h-screen flex flex-col justify-between bg-black text-white pb-10 md:pb-64 lg:pb-10 relative overflow-hidden px-4 lg:px-0">
            <div className="absolute -left-8 xl:left-0 bottom-0 hidden sm:block h-auto xl:h-72 3xl:h-auto">
                <img src={doodle} alt="dawtona_logo" className="h-full" />
            </div>
            <div className="absolute top-1/4 lg:top-1/3 xl:-top-12 3xl:-top-24 -right-32 xl:-right-32 3xl:-right-56 z-0 hidden md:block w-3/4 xl:w-1/2 3xl:w-auto">
                <img src={juice} alt="dawtona_logo" />
            </div>
            <div className="container mx-auto relative z-10 lg:pl-20">
                <div className="flex pt-24 md:pt-16 xl:pt-24 2xl:pt-16 3xl:pt-12 h-40 -mt-10 xl:-mt-16 2xl:-mt-4 3xl:-mt-0">
                    <div className="mr-10 md:mr-20 h-full flex-shrink-0">
                        <img src={logo} alt="dawtona_logo" className="h-full" />
                    </div>
                    <div className="h-full flex-shrink-0">
                        <img src={wosp_logo} alt="wosp_logo" className="h-full" />
                    </div>
                </div>
                <h1 className="text-3xl sm:text-4xl xl:text-4xl 2xl:text-5xl font-bold mt-4 uppercase leading-none">
                    Gramy razem!
                </h1>
                <div className="text-base 2xl:text-xl mt-4 leading-none 2xl:leading-tight">
                    Dziękujemy, zapisaliśmy twój adres. <br />
                    Jak tylko wystartuje Konkurs, otrzymasz od nas informację.
                    <br /><br />
                    W tym roku Dawtona gra razem z WOŚP by zapewnić najwyższe <br className="hidden md:block" />
                    standardy diagnostyki i leczenia wzroku <br className="hide-2" /> u dzieci. Gramy razem, <br className="hidden md:block" /> bo sok
                    pomidorowy ma moc! Pomoże ci zadbać o twój wzrok i zdrowie.
                    <br /><br />
                    <span className="font-bold">
                        Do usłyszenia 10 stycznia!
                    </span>
                </div>
            </div>
            <div className="container mx-auto relative z-20 lg:pl-20">
                <div className="mt-16 flex text-base xl:text-sm 3xl:text-lg">
                    © Dawtona 2022
                    <Link to="/ochrona-danych-osobowych" target="_blank" className="ml-10">
                        polityka prywatności
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Potwierdzenie;
