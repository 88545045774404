import { Link } from "react-router-dom"

const Footer = () => {
    return (
        <div className="w-full h-6 2xl:h-10 text-white px-4 3xl:px-0 relative z-20">
            <div className="container mx-auto h-full text-xs 2xl:text-base">
                <div className="flex items-center h-full">
                    <span className="mr-10">
                        &copy; Dawtona
                    </span>
                    <span className="mr-10">
                        <Link to="/regulamin" target={'_blank'}>Regulamin</Link>
                    </span>
                    <span>
                        <Link to="/polityka-prywatnosci" target={'_blank'}>Polityka prywatności</Link>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Footer